import { format } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Box } from '@material-ui/core'
import {
  formatUrl,
  formatFriendsOrderedCount,
  formatAverageExpectedOrders,
  createRewardsHoverMessage,
} from '../../util/formatUtils'
import { placeOrderRoute } from '../../routes/routes'
import ComingSoon from '../coming-soon/ComingSoon'
import SkeletonLoadingDeliverySection from './SkeletonLoadingDeliverySection'
import DeliverySection from './DeliverySection'
import DailyHeader from './DailyHeader'
import LoadingDeliverySectionError from './LoadingDeliverySectionError'
import DeliverySectionCard from './DeliverySectionCard'
import RestaurantLogo from './RestaurantLogo'
import RestaurantName from './RestaurantName'
import DailyRestaurantDeliveryDetail from './DailyRestaurantDeliveryDetail'
import SelectedDayOrderCard from './SelectedDayOrderCard'
import NoDeliveriesToday from './NoDeliveriesToday'
import { findFriendProfileForUser } from './utils/orderUtils'

const DailyDeliverySection = ({
  deliveries,
  friendsOrdered,
  locationId,
  selectedDate,
  isSelectedDayActive,
  activationDate,
  isLoadingDeliveries,
  errorLoading,
  rewards,
  isLoadingRewards,
  selectDateStart,
  selectedDateOrders,
}) => {
  const isAuthenticated = useSelector(state => state?.user?.isAuthenticated)
  const favoritesCountByMenuId = useSelector(state => state?.favorite?.favoritesCountByMenuId) || []
  const currentUserProfile = useSelector(state => state?.profile?.profile)

  const date = new Date()
  const dayFormat = 'D'
  const timeFormat = 'HHmm'
  const isLimitedOrders = delivery => delivery.weightLeft < 7 && delivery.weightLeft > 0
  const isSoldOut = delivery => delivery.weightLeft <= 0
  const isNearCutOff = delivery =>
    format(delivery.cutoffDate, timeFormat) - format(date, timeFormat) < 16 &&
    format(delivery.cutoffDate, timeFormat) - format(date, timeFormat) > 0 &&
    Number(format(delivery.cutoffDate, dayFormat)) === date.getDate()
  const isCreatedByUser = delivery => delivery.deliveryType === 6 && delivery.createdBy != null

  const cardDropoffClassName = (delivery, hasFavorite) => {
    const deliveryState = delivery.isPastCutoff
      ? 'is-tooLate'
      : isSoldOut(delivery)
      ? 'is-soldOut'
      : isNearCutOff(delivery)
      ? 'is-nearCutOff'
      : isLimitedOrders(delivery)
      ? 'is-limitedOrders'
      : isCreatedByUser(delivery)
      ? 'is-createdByUser'
      : delivery.isNewRestaurantAtLocation
      ? 'is-new'
      : ''
    const favoriteState = hasFavorite ? 'has-favorites' : ''
    const reserveState =
      delivery.deliveryType === 6 && !delivery.isPastCutoff && !isSoldOut(delivery)
        ? 'is-reserved'
        : ''
    const dailyState =
      (delivery.deliveryType === 6 || delivery.deliveryType === 0 || delivery.deliveryType === 4) &&
      !delivery.isPastCutoff
        ? 'is-daily-dropoff'
        : ''

    return [deliveryState, favoriteState, reserveState, dailyState].filter(x => !!x).join(' ')
  }

  const isNewBuilding = !isSelectedDayActive && activationDate
  return (
    <Box style={{ position: 'relative', paddingBottom: 4 }}>
      <DeliverySection
        HeaderComponent={() => (
          <DailyHeader
            selectedDate={selectedDate}
            selectDateStart={selectDateStart}
            locationId={locationId}
          />
        )}
      >
        {selectedDateOrders.length > 0 &&
          selectedDateOrders.map(order => {
            return (
              <SelectedDayOrderCard
                key={order.orderId}
                selectedDateOrder={order}
                locationId={locationId}
              />
            )
          })}
        {errorLoading && !isLoadingDeliveries && <LoadingDeliverySectionError />}
        {isLoadingDeliveries || isLoadingRewards ? (
          <SkeletonLoadingDeliverySection />
        ) : (
          deliveries.map(delivery => {
            const friendsOrders = friendsOrdered?.find(
              f => f.dropoffId === delivery.deliveryDropoffId,
            )?.friendsOrders
            const dropoffRewards = rewards?.find(
              r => r.deliveryDropoffId === delivery.deliveryDropoffId,
            )?.nextRewardsPercentage
            const numberOfOrdersPerRewardsPercentage = rewards?.find(
              r => r.deliveryDropoffId === delivery.deliveryDropoffId,
            )?.numberOfOrdersPerPercentage
            const isScheduledByCurrentUser = currentUserProfile?.userId === delivery.createdBy
            const averageExpectedOrders = formatAverageExpectedOrders(
              delivery.averageOrdersPerDeliveryWithOrders,
            )
            const currentOrdersCount = formatFriendsOrderedCount(friendsOrders)
            const rewardsHoverMessage = createRewardsHoverMessage(
              currentOrdersCount,
              averageExpectedOrders,
              cardDropoffClassName(delivery, favoritesCountByMenuId[delivery.menuId] > 0),
              numberOfOrdersPerRewardsPercentage,
            )

            return (
              <DeliverySectionCard
                key={delivery.deliveryId}
                createdByFriendProfile={findFriendProfileForUser(friendsOrders, delivery.createdBy)}
                averageExpectedOrders={averageExpectedOrders}
                isScheduledByCurrentUser={isScheduledByCurrentUser}
                currentOrderCount={currentOrdersCount}
                classNames={cardDropoffClassName(
                  delivery,
                  favoritesCountByMenuId[delivery.menuId] > 0,
                )}
                rewardsHoverMessage={rewardsHoverMessage}
                dropoffRewards={dropoffRewards}
                isCardActive={!delivery.isPastCutoff && !isSoldOut(delivery)}
                ordersRemaining={delivery.weightLeft}
                url={formatUrl(placeOrderRoute.path, {
                  dropoffId: delivery.deliveryDropoffId,
                  locationId: locationId,
                })}
                LogoComponent={() => (
                  <RestaurantLogo
                    restaurantName={delivery.restaurantName}
                    logoLink={delivery.logoUrl}
                  />
                )}
                HeaderComponent={() => (
                  <RestaurantName
                    name={delivery.restaurantName}
                    favoritesCount={favoritesCountByMenuId[delivery.menuId]}
                  />
                )}
                SubheaderComponent={() => (
                  <DailyRestaurantDeliveryDetail
                    cutoffTime={delivery.cutoffDate}
                    cutoffDisplay={delivery.cutoffDisplay}
                    dropoffDisplay={delivery.dropoffDisplay}
                    deliveryFeeContribution={delivery.deliveryFeeContribution}
                    deliveryFee={delivery.deliveryFee}
                    friendsOrdered={friendsOrders}
                    averageExpectedOrders={averageExpectedOrders}
                    createdBy={delivery.createdBy}
                    currentUserProfile={currentUserProfile}
                    friendImageUrlBase={process.env.REACT_APP_IMAGE_LAMBDA}
                    rewardsHoverMessage={rewardsHoverMessage}
                  />
                )}
              />
            )
          })
        )}
        {isEmpty(deliveries) && !isNewBuilding && !isLoadingDeliveries && (
          <NoDeliveriesToday selectedDate={selectedDate} />
        )}
        {isNewBuilding && (
          <ComingSoon
            activationDate={activationDate}
            isAuthenticated={isAuthenticated}
            locationId={locationId}
            onChangeDay={date => selectDateStart(date, locationId)}
          />
        )}
      </DeliverySection>
    </Box>
  )
}

export default DailyDeliverySection
