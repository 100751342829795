import React, { useState } from 'react'

import { CheckCircle, ChevronRight, Close } from '@material-ui/icons'

import { Box, Button, Chip, MenuItem, Menu, Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { connect } from 'react-redux'
import { formatUrl } from '../../util/formatUtils'

import { locationRoute } from '../../routes/routes'
import { updateAndSaveUserLocationStart } from '../../redux/modules/user'
import { DIALOG_OPTIONS } from './savedLocationsUtils'

const useStyles = makeStyles(theme => ({
  menuRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(3),
  },
  menuTitleRow: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
    marginBottom: theme.spacing(3),
  },
  menuTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  closeIcon: {
    padding: 0,
  },
  removeRowItem: {
    color: theme.palette.error.main,
  },
}))

const OptionsCell = ({
  deliveryLocationId,
  id,
  setDialogToShow,
  handleSelectLocation,
  deliveryInstructionsSupported,
  updateAndSaveUserLocationStart,
}) => {
  const classes = useStyles()
  const mobileView = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const [anchorEl, setAnchorEl] = useState(null)

  const handleShowMenu = event => {
    setAnchorEl(event.target)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleEditNickname = () => {
    handleSelectLocation(id)
    setDialogToShow(DIALOG_OPTIONS.EDIT_NICKNAME.id)
    handleCloseMenu()
  }
  const handleEditDeliveryInstructions = () => {
    handleSelectLocation(id)
    setDialogToShow(DIALOG_OPTIONS.EDIT_DELIVERY_INSTRUCTIONS.id)
    handleCloseMenu()
  }
  const handleSeeDeliveries = () => {
    handleCloseMenu()
    goToLocation(id)
  }
  const handleSetDefaultLocation = () => {
    handleCloseMenu()
    updateAndSaveUserLocationStart(id)
  }
  const handleRemoveLocation = () => {
    handleSelectLocation(id)
    setDialogToShow(DIALOG_OPTIONS.REMOVE_LOCATION.id)
    handleCloseMenu()
  }

  const goToLocation = locationId => {
    window.location.href = formatUrl(locationRoute.path, { locationId: locationId })
  }

  return (
    <Box
      data-testid="option-cell"
      marginLeft={2}
      marginRight={4}
      display="flex"
      alignItems="center"
      flexDirection={mobileView ? 'column-reverse' : 'row'}
      justifyContent="flex-end"
    >
      <Box margin={2} marginLeft={4} marginRight={4}>
        {deliveryLocationId === id && (
          <Chip color="secondary" icon={<CheckCircle />} label="Default" size="small" />
        )}
      </Box>
      <Box marginLeft={2} marginRight={2}>
        <Button variant="contained" onClick={e => handleShowMenu(e)}>
          Actions
        </Button>
      </Box>
      {!!anchorEl && (
        <Menu id="basic-menu" anchorEl={anchorEl} open={!!anchorEl} onClose={handleCloseMenu}>
          <Box padding={3} width={350}>
            <Box className={cn(classes.menuRow, classes.menuTitleRow)}>
              <Typography className={classes.menuTitle} fontWeight={800}>
                Location Options
              </Typography>
              <Button className={classes.closeIcon} onClick={handleCloseMenu} padding={0}>
                <Close />
              </Button>
            </Box>
            <MenuItem className={classes.menuRow} onClick={handleEditNickname}>
              <Typography>Edit Nickname</Typography>
              <Box display="flex" marginLeft={2}>
                <ChevronRight />
              </Box>
            </MenuItem>
            {deliveryInstructionsSupported && (
              <MenuItem className={classes.menuRow} onClick={handleEditDeliveryInstructions}>
                <Typography>Edit Delivery Instructions</Typography>
                <Box display="flex" marginLeft={2}>
                  <ChevronRight />
                </Box>
              </MenuItem>
            )}
            <MenuItem className={classes.menuRow} onClick={handleSeeDeliveries}>
              <Typography>See Deliveries</Typography>
              <Box display="flex" marginLeft={2}>
                <ChevronRight />
              </Box>
            </MenuItem>
            <MenuItem className={classes.menuRow} onClick={handleSetDefaultLocation}>
              <Typography>Set to Default</Typography>
              <Box display="flex" marginLeft={2}>
                <ChevronRight />
              </Box>
            </MenuItem>
            <MenuItem className={classes.menuRow} onClick={handleRemoveLocation}>
              <Typography className={classes.removeRowItem}>Remove Location</Typography>
              <Box display="flex" marginLeft={2}>
                <ChevronRight className={classes.removeRowItem} />
              </Box>
            </MenuItem>
          </Box>
        </Menu>
      )}
    </Box>
  )
}

const mapDispatchToProps = {
  updateAndSaveUserLocationStart,
}

export default connect(null, mapDispatchToProps)(OptionsCell)
