export const LocationType = Object.freeze({
  GROUP_ORDER: 'GroupOrder',
  MANAGED_PROPERTY: 'ManagedProperty',
  RESIDENTIAL_COMPLEX: 'ResidentialComplex',
  RESIDENTIAL_HOME: 'ResidentialHome',
  RESIDENTIAL_ZONE: 'ResidentialZone',
  SINGLE_COMPANY: 'SingleCompany',
})

export const FOODSBY_ADDRESS = Object.freeze({
  STREET: '3001 Broadway St NE',
  CITY: 'Minneapolis',
  STATE: 'MN',
  ZIP: '55413',
})

export const UNKNOWN_CUSTOMER = 'Foodsby Customer'
