import React, { useState } from 'react'
import { connect } from 'react-redux'
import MenuIcon from '@material-ui/icons/Menu'
import { Link as RouterLink } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Link,
  List,
  ListSubheader,
  Box,
  useMediaQuery,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Close } from '@material-ui/icons'

import {
  selectCurrentUser,
  selectIsAuthenticated,
  selectProfile,
} from '../../../redux/modules/selectors'
import { selectPreferredOffices } from '../../../redux/selectors/accountSelectors'
import { selectUserTeam, selectTeamsSupported } from '../../../redux/selectors/userTeamSelectors'
import ContentCards from '../../contentCards/ContentCards'
import { selectLocationPreferred } from '../../../redux/selectors/locationSelectors'
import NavImpersonation from './NavImpersonation'
import FlyoutLinks from './FlyoutLinks'
import HeaderLocation from './HeaderLocation'
import HeaderDeliveryDate from './HeaderDeliveryDate'

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  drawer: {
    width: 240,
  },
  drawerRoot: {
    zIndex: '999999 !important',
  },
  zIndex: '999999 !important',
}))

const Header = ({
  currentUser,
  isAuthenticated,
  userTeam,
  profile,
  isTeamsSupported,
  publicMenuLinkMode = true,
  preferredOffices,
  isPreferredLocation,
  showHeaderLocation = false,
  showHeaderDeliveryDate = false,
}) => {
  const classes = useStyles()
  const [showDrawer, setShowDrawer] = useState(false)
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'))

  const isImpostor =
    isAuthenticated && (currentUser?.authorities?.includes('ROLE_IMPOSTOR') ?? false)
  const isConfirmedUser = isAuthenticated && profile?.status === 'CONFIRMED'
  return (
    <>
      <AppBar position="fixed" color="inherit">
        <Toolbar>
          <Link component={RouterLink} to={'/'}>
            <img
              alt="Foodsby Logo"
              component="img"
              src={`${process.env.REACT_APP_BASE_ORDER_URL}/images/logo-foodsby.png`}
              title="Foodsby Logo"
              style={{ maxHeight: 36 }}
            />
          </Link>
          {isDesktop && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="start"
              ml={'6em'}
              style={{ gap: '2em' }}
            >
              {showHeaderLocation ? <HeaderLocation /> : null}
              {showHeaderDeliveryDate ? <HeaderDeliveryDate /> : null}
            </Box>
          )}
          <div style={{ flexGrow: 1 }} />
          {isConfirmedUser && (
            <>
              <ContentCards />
              <IconButton id="menuIconButton" onClick={() => setShowDrawer(true)}>
                <MenuIcon />
              </IconButton>
            </>
          )}
        </Toolbar>
        {isImpostor && <NavImpersonation />}
      </AppBar>

      {isConfirmedUser && (
        <Drawer
          anchor="right"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          classes={{ paper: classes.drawer, root: classes.drawerRoot }}
        >
          <List>
            <ListSubheader className={classes.listHeader}>
              <IconButton style={{ float: 'right' }} onClick={() => setShowDrawer(false)}>
                <Close />
              </IconButton>
            </ListSubheader>
          </List>
          <FlyoutLinks
            currentUser={currentUser}
            userTeam={userTeam}
            isTeamsSupported={isTeamsSupported}
            onClose={() => setShowDrawer(false)}
            preferredOffices={preferredOffices}
            isPreferredLocation={isPreferredLocation}
          />
        </Drawer>
      )}

      <div className={classes.offset} />
      {/* we need another one of these if impersonating */}
      {isImpostor && <div className={classes.offset} />}
    </>
  )
}

function mapStateToProps(state) {
  const isAuthenticated = selectIsAuthenticated(state)
  const currentUser = selectCurrentUser(state)
  const profile = selectProfile(state)
  const userTeam = selectUserTeam(state)
  const isTeamsSupported = selectTeamsSupported(state)
  const preferredOffices = selectPreferredOffices(state)
  const isPreferredLocation = selectLocationPreferred(state)

  return {
    isAuthenticated,
    currentUser,
    profile,
    userTeam,
    isTeamsSupported,
    preferredOffices,
    isPreferredLocation,
  }
}

Header.defaultProps = {
  menuItems: [],
}

export default connect(mapStateToProps)(Header)
