import { Spinner } from '@foodsby/nutrient'
import React from 'react'

import HowItWorks from './HowItWorks'
import PreferMobile from './PreferMobile'
import RestaurantShowcase from './RestaurantShowcase'

export const OverSixDays = ({
  JoinForm,
  isAuthenticated,
  loadingDeliveringRestaurants,
  locationId,
  restaurantLogos,
}) => {
  return (
    <div className="row center-xs">
      <div className="col-xs-12 coming-soon-content">
        <h4>
          {isAuthenticated
            ? 'A better delivery experience is almost here!'
            : 'A better delivery experience is coming!'}
        </h4>
        <p>
          {isAuthenticated
            ? 'Thanks for creating your account. You will receive an email on the first morning when Foodsby becomes available.'
            : 'Sign up today to receive special offers and get notified when Foodsby launches.'}
        </p>
        {!isAuthenticated && (
          <div className="row center-xs" styles={{ marginBottom: '16px' }}>
            <div className="col-xs-8">
              <JoinForm locationId={locationId} />
            </div>
          </div>
        )}
        <hr className="coming-soon-hr" />
        <HowItWorks />
        <hr className="coming-soon-hr" />
        <PreferMobile />
        <hr className="coming-soon-hr" />
        {loadingDeliveringRestaurants ? (
          <Spinner className="spinner-lg primary" />
        ) : (
          <RestaurantShowcase restaurantLogos={restaurantLogos} />
        )}
      </div>
    </div>
  )
}

export default OverSixDays
