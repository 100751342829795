import React from 'react'
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import { connect, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { push } from 'connected-react-router'
import ButtonDialogClose from '../teams/common/ButtonDialogClose'
import { selectProfile } from '../../redux/modules/selectors'
import { upgradeToPreferredStart } from '../../redux/modules/account'
import ButtonSubmit from '../common/ButtonSubmit'
import { phoneRegExp } from '../common/InputMasks'
import { formatUrl } from '../../util/formatUtils'
import { teamAccountRoute } from '../../routes/routes'

const schema = object().shape({
  userFirstName: string().required('Please enter your name.'),
  userLastName: string().required('Please enter your name.'),
  userPhone: string().matches(phoneRegExp, 'Please enter a valid phone number.'),
})

const useStyles = makeStyles(theme => ({
  dialogAction: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
}))

const DialogUpgradeOfficeYourInfo = ({
  closeDialog,
  formData,
  profile,
  officeId,
  isUpgradingAccountToPreferred,
  errorUpgradingAccountToPreferred,
  upgradeToPreferredStart,
  isDoneUpgrading,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleSubmit = values => {
    const payload =
      values.userPhone === ''
        ? { ...formData, ...values, userPhone: profile?.phone }
        : { ...formData, ...values }
    upgradeToPreferredStart(officeId, payload)
  }

  useEffect(() => {
    if (!isDoneUpgrading) {
      return
    }
    dispatch(push(formatUrl(teamAccountRoute.path, { accountId: officeId })))
  }, [isDoneUpgrading, dispatch, officeId])

  return (
    <>
      <DialogTitle disableTypography>
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            <strong>Step 2:</strong> Tell us about you
          </Typography>
        </Box>
        <ButtonDialogClose onClick={closeDialog} />
      </DialogTitle>
      <Formik
        initialValues={{
          userFirstName: profile?.firstName ?? '',
          userLastName: profile?.lastName ?? '',
          userPhone: profile?.phone ?? '',
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <DialogContent>
              <Typography style={{ fontWeight: '500' }}>What&apos;s your name?</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    error={touched.userFirstName && Boolean(errors.userFirstName)}
                    helperText={touched.userFirstName && errors.userFirstName}
                    fullWidth
                    id="userFirstName"
                    name="userFirstName"
                    margin="dense"
                    placeholder="First Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.userFirstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={touched.userLastName && Boolean(errors.userLastName)}
                    helperText={touched.userLastName && errors.userLastName}
                    fullWidth
                    id="userLastName"
                    name="userLastName"
                    margin="dense"
                    placeholder="Last Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.userLastName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: '500' }}>
                    What&apos;s your phone number? (optional)
                  </Typography>
                  <TextField
                    error={touched.userPhone && Boolean(errors.userPhone)}
                    helperText={touched.userPhone && errors.userPhone}
                    fullWidth
                    id="userPhone"
                    name="userPhone"
                    margin="dense"
                    placeholder="###-###-####"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.userPhone}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {errorUpgradingAccountToPreferred ? (
                <Box marginTop={2} textAlign="center">
                  <Typography color="error">
                    Something went wrong while submitting your request, please email us at
                    support@foodsby.com.
                  </Typography>
                </Box>
              ) : null}
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
              <ButtonSubmit
                variant="contained"
                color="primary"
                submitting={isUpgradingAccountToPreferred}
              >
                Finish Setup
              </ButtonSubmit>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  )
}

const mapStateToProps = state => {
  const profile = selectProfile(state)
  return {
    profile,
    isUpgradingAccountToPreferred: state.account.isUpgradingAccountToPreferred,
    errorUpgradingAccountToPreferred: state.account.errorUpgradingAccountToPreferred,
    isDoneUpgrading: state.account.isDoneUpgrading,
  }
}

const mapDispatchToProps = {
  upgradeToPreferredStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogUpgradeOfficeYourInfo)
