import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Checkbox } from '@material-ui/core'
import { format, parse } from 'date-fns'

import {
  toggleSelectMember,
  setPage,
  setSort,
  toggleSelectOnePageMembers,
  toggleSelectAllMembers,
  setActionableMembers,
  updateMemberRoleStart,
} from '../../../redux/modules/teamMembers'
import {
  selectMembersRows,
  selectTeamMembers,
  selectFilteredMembers,
} from '../../../redux/selectors/teamMembersSelectors'
import TableMembers from '../common/TableMembers'
import { selectCurrentUser } from '../../../redux/modules/selectors'
import AvatarMember from '../../common/AvatarMember'
import ToolboxMemberActions from './ToolboxMemberActions'
import DialogRemoveMember from './DialogRemoveMember'
import { isRowSelected } from './adminUtils'
import SelectMemberRole from './SelectMemberRole'
import MemberName from './MemberName'

const TableAdminMembers = ({
  currentUser,
  teamMembers,
  filteredMembers,
  rows,
  page,
  sort,
  isLoadingTeamMembers,
  selectedMembers,
  isUpdatingMemberRole,
  toggleSelectMember,
  toggleSelectOnePageMembers,
  toggleSelectAllMembers,
  setPage,
  setSort,
  setActionableMembers,
  updateMemberRoleStart,
}) => {
  const showRemindFeature = false

  const [showRemoveDialog, setShowRemoveDialog] = useState(false)

  const handleClickRemove = () => {
    setShowRemoveDialog(true)
    setActionableMembers()
  }

  const handleSelectMember = member => toggleSelectMember(member)

  const handleSelectOnePage = checked => toggleSelectOnePageMembers({ checked, rows })

  const handleSelectAll = checked => toggleSelectAllMembers({ checked, allMembers: teamMembers })

  const columns = [
    {
      id: 'avatarId',
      label: '',
      sortable: false,
    },
    {
      id: 'lastName', // This field is named lastName for sorting purposes
      label: 'Name',
      sortable: true,
    },
    {
      id: 'confirmedAt',
      label: 'Joined',
      sortable: false,
    },
    {
      id: 'isAdmin',
      label: 'Role',
      sortable: true,
    },
    {
      id: 'reminder',
      label: '',
      sortable: false,
    },
  ]

  const rowCells = rows.map((member, index) => {
    const labelId = `members-table-checkbox-${index}`
    return {
      memberId: member.userId,
      cells: [
        {
          Component: (
            <Checkbox
              key={member.userId}
              checked={isRowSelected(selectedMembers, member)}
              inputProps={{ 'aria-labelledby': labelId }}
              onClick={() => handleSelectMember(member)}
            />
          ),
        },
        {
          Component: <AvatarMember member={member} />,
        },
        {
          labelId: labelId,
          Component: <MemberName member={member} />,
        },
        {
          Component: (
            <>{member.confirmedAt ? format(parse(member.confirmedAt), 'MM/DD/YY') : '-'}</>
          ),
        },
        {
          Component: (
            <SelectMemberRole
              currentUser={currentUser}
              member={member}
              submitting={isUpdatingMemberRole}
              onChange={e => updateMemberRoleStart(member.userId, member.email, e.target.value)}
            />
          ),
        },
        {
          Component: (
            <>
              {showRemindFeature && member.status === 'INVITED' && (
                <Button color="primary">Remind</Button>
              )}
            </>
          ),
        },
      ],
    }
  })

  return (
    <>
      <TableMembers
        checkbox
        columns={columns}
        teamMembers={teamMembers}
        filteredMembers={filteredMembers}
        selectedMembers={selectedMembers}
        rows={rows}
        rowCells={rowCells}
        page={page}
        sort={sort}
        onSelectOnePage={handleSelectOnePage}
        onSelectAll={handleSelectAll}
        setPage={setPage}
        setSort={setSort}
        loading={isLoadingTeamMembers}
        Toolbox={<ToolboxMemberActions onShowRemoveDialog={handleClickRemove} />}
      />
      {showRemoveDialog && <DialogRemoveMember onClose={() => setShowRemoveDialog(false)} />}
    </>
  )
}

const mapStateToProps = state => {
  const currentUser = selectCurrentUser(state)
  const teamMembers = selectTeamMembers(state)
  const filteredMembers = selectFilteredMembers(state)
  const rows = selectMembersRows(state)
  const {
    isLoadingTeamMembers,
    selectedMembers,
    page,
    search,
    sort,
    isUpdatingMemberRole,
  } = state.teamMembers

  return {
    currentUser,
    teamMembers,
    filteredMembers,
    rows,
    isLoadingTeamMembers,
    selectedMembers,
    page,
    search,
    sort,
    isUpdatingMemberRole,
  }
}

const mapDispatchToProps = {
  toggleSelectMember,
  toggleSelectOnePageMembers,
  toggleSelectAllMembers,
  setPage,
  setSort,
  setActionableMembers,
  updateMemberRoleStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(TableAdminMembers)
