import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  addressLine: {
    fontSize: theme.typography.caption.fontSize,
    margin: '2px 0',
  },
  firstAddressLine: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

const AddressCell = ({ location }) => {
  const classes = useStyles()

  const fillAddressLines = address => {
    const { name, deliveryLocationName, street, city, state, zip } = address
    return [name || deliveryLocationName, street, `${city}, ${state} ${zip}`].filter(Boolean)
  }

  return (
    <Box width="100%" marginLeft={4} marginRight={2}>
      {fillAddressLines(location).map((line, i) => {
        return (
          <Typography
            key={`${line}-${i}`}
            className={cn(classes.addressLine, { [classes.firstAddressLine]: i === 0 })}
          >
            {line}
          </Typography>
        )
      })}
    </Box>
  )
}

export default AddressCell
