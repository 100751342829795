// polyfills
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'regenerator-runtime/runtime'
import 'raf/polyfill'
/* Styles */
import '@foodsby/nutrient/src/css/app.css'
import 'react-dropdown/style.css'
import './styles/index.css'

import { themeConWeb } from '@app/util/modernThemeConweb'
import { refreshToken as refreshTokenApi } from '@foodsby/webapp-jwt'
import { ThemeProvider } from '@material-ui/core/styles'
import * as braze from '@braze/web-sdk'
import { ConnectedRouter } from 'connected-react-router'
// on to the fun
import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Redirect, Route } from 'react-router'
import { Switch } from 'react-router-dom'
import { ThemeProvider as ThemeProviderStyled } from 'styled-components'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { SnackbarProvider } from 'notistack'
import { setWebpSupported } from '../src/redux/modules/browser'
import { isWebpSupported } from '../src/util/fileUtils'
import { FOODSBY_BASE_URL } from './api/api'
import HomeRedirect from './components/home/HomeRedirect'
import PrivacyPolicyPage from './components/legal/PrivacyPolicyPage'
import TermsOfUsePage from './components/legal/TermsOfUsePage'
import TermsOfUseRewardsPage from './components/legal/TermsOfUseRewardsPage'
import Logout from './components/login/Logout'
import ProfileSidebar from './components/profile/ProfileSidebar'
import theme from './createMuiTheme'
import {
  PageChangePassword,
  PageCheckout,
  PageConfirmation,
  PageConfirmEmailAddress,
  PageContact,
  PageForgotPassword,
  PageGiftRedemption,
  PageHome,
  PageLogin,
  PageMaintenance,
  PageOrderHistory,
  PagePlaceOrder,
  PagePlaceCateringOrder,
  PageProfile,
  PageReferralTermsAndConditions,
  PageRegister,
  PageSlack,
  PageSubscriptionTermsAndConditions,
  PageUI,
  PageUnlimitedDelivery,
  PagePerksCredits,
  PageSurvey,
  PageError,
  PageNotFound,
  PagePlaceReserveOrder,
} from './pages'
import PageSelectLocation from './pages/PageSelectLocation'
import { setEntryPoint } from './redux/modules/user'
import store, { history } from './redux/store'
import { RouteLayoutPublic, RouteLayoutPrivate, RouteLayoutSidebar } from './routes/layoutRoutes'
import {
  branchReferralRoute,
  changePasswordRoute,
  checkoutRoute,
  completeAccountRoute,
  confirmationRoute,
  confirmEmailAddressRoute,
  contactRoute,
  forgotPasswordRoute,
  friendsRoute,
  historyRoute,
  homeRoute,
  locationRoute,
  cateringRoute,
  reserveRoute,
  loginRoute,
  logoutRoute,
  maintenanceRoute,
  placeOrderRoute,
  privacyPolicyRoute,
  profileRoute,
  redeemGiftRoute,
  referralRoute,
  referralsRoute,
  referralTermsAndConditionsRoute,
  registerRoute,
  rootRoute,
  selectLocationRoute,
  slackRoute,
  subscriptionsPromoRoute,
  subscriptionsRoute,
  subscriptionTermsAndConditionsRoute,
  termsAndConditionsRewardsRoute,
  termsAndConditionsRoute,
  uiRoute,
  unsubscribeRoute,
  perksCreditsRoute,
  teamRoute,
  teamInviteRoute,
  surveyRoute,
  formRoute,
  locationSearchRoute,
  errorRoute,
  savedLocationsRoute,
  placeCateringOrderRoute,
  pickCateringDeliveryDateAndTimeRoute,
  pickReserveDeliveryDateAndTimeRoute,
  placeReserveOrderRoute,
  setDeliveryReserveFeeRoute,
} from './routes/routes'
import { logException } from './util/errorUtils'
import { interceptInAppMessageToAskToShowWebPushNotifications } from './util/braze'
import { initTokens, isImpersonatingUser, isWithoutAccessToken } from './util/webStorageUtils'
import PageTeamAdmin from './pages/PageTeamAdmin'
import Snackbars from './components/common/Snackbars'
import PageTeamInvite from './pages/PageTeamInvite'
import PageLocationSearch from './pages/PageLocationSearch'
import PageSavedLocations from './pages/PageSavedLocations'
import RouteWithGlobalLoader from './routes/RouteWithGlobalLoader'
import PagePickCateringDeliveryDateTime from './pages/PagePickCateringDeliveryDateTime'
import PagePickReserveDeliveryDateTime from './pages/PagePickReserveDeliveryDateTime'
import PageSetDeliveryReserveFee from './pages/PageSetDeliveryReserveFee'
import PageCateringHome from './pages/PageCateringHome'
import PageReserveHome from './pages/PageReserveHome'

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
const urlSearchParams = new URLSearchParams(window.location.search)

function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

// set up JWT
initTokens()

// Update the old service worker if found
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    // returns installed service workers
    if (registrations.length) {
      // eslint-disable-next-line
      for (let registration of registrations) {
        registration.update()
      }
    }
  })
}

braze.initialize(process.env.REACT_APP_BRAZE_API_KEY, {
  baseUrl: 'https://sdk.iad-03.braze.com/api/v3',
  allowUserSuppliedJavascript: true,
  enableLogging: process.env.NODE_ENV !== 'production',
  minimumIntervalBetweenTriggerActionsInSeconds: 15,
  safariWebsitePushId: 'web.com.foodsby.order',
  inAppMessageZIndex: 1200,
})

braze.subscribeToInAppMessage(interceptInAppMessageToAskToShowWebPushNotifications)

braze.openSession()
if (braze.isPushPermissionGranted() && braze.isPushSupported()) {
  braze.requestPushPermission()
}
window.braze = braze

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

async function renderApp() {
  // Force Refresh JWT
  // We eventually want the jwt library to support in-memory access token
  // So every time we render the app we can get a new access token
  // For now we manually delete the access token and
  localStorage.removeItem('fb-token')
  try {
    // Refresh JWT if needed
    if (isWithoutAccessToken() && !isImpersonatingUser()) {
      try {
        await refreshTokenApi(
          {
            clientId: CLIENT_ID,
            clientSecret: CLIENT_SECRET,
          },
          {
            baseURL: FOODSBY_BASE_URL,
          },
        )
      } catch (ex) {
        console.error(ex)
      }
    }

    // Setup webp support
    const hasWebpSupport = await isWebpSupported()
    store.dispatch(setWebpSupported(hasWebpSupport))

    // Setup origin tracking
    // TODO: Replace this method of checking the facebook ads with something less fragile. Probably a Branch.io integration?
    if (
      urlSearchParams
        ?.get('utm_source')
        ?.toLowerCase()
        ?.includes('fb ads')
    ) {
      store.dispatch(setEntryPoint('FACEBOOK'))
    }

    // mount app
    const root = document.getElementById('root')
    if (root) {
      ReactDOM.render(
        <ThemeProviderStyled
          theme={{
            ...themeConWeb,
            glyphs: {
              // Temp hack for nutrient icons
              daily: { content: "'\\0046'" },
              facebook: { content: "'\\0041'" },
              hamburger: { content: "'\\0042'" },
              instagram: { content: "'\\0043'" },
              linkedin: { content: "'\\0044'" },
              meetings: { content: "'\\0045'" },
              twitter: { content: "'\\0047'" },
            },
          }}
        >
          <Elements stripe={stripePromise}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={3} preventDuplicate>
                <Provider store={store}>
                  <ConnectedRouter history={history}>
                    <Switch>
                      {/* Sign up / Log in */}
                      <RouteLayoutPublic
                        component={PageLogin}
                        path={loginRoute.path}
                        title={loginRoute.title}
                      />
                      <RouteLayoutPublic
                        component={PageChangePassword}
                        path={changePasswordRoute.path}
                        title={changePasswordRoute.title}
                      />
                      <RouteLayoutPublic
                        component={PageForgotPassword}
                        path={forgotPasswordRoute.path}
                        title={forgotPasswordRoute.title}
                      />
                      <RouteLayoutPublic
                        component={PageRegister}
                        layoutProps={{
                          childrenContainer: Fragment,
                          flex: true,
                          hideFooter: true,
                          hideHeader: true,
                        }}
                        path={registerRoute.path}
                        title={registerRoute.title}
                      />
                      <RouteLayoutSidebar
                        component={PageConfirmEmailAddress}
                        path={confirmEmailAddressRoute.path}
                        title={confirmEmailAddressRoute.title}
                      />

                      {/* Legal documents */}
                      <RouteLayoutPublic
                        component={PrivacyPolicyPage}
                        layoutProps={{
                          hideFooter: true,
                        }}
                        path={privacyPolicyRoute.path}
                        title={privacyPolicyRoute.title}
                      />
                      <RouteLayoutPublic
                        component={TermsOfUsePage}
                        layoutProps={{
                          hideFooter: true,
                        }}
                        path={termsAndConditionsRoute.path}
                        title={termsAndConditionsRoute.title}
                      />
                      <RouteLayoutPublic
                        component={TermsOfUseRewardsPage}
                        layoutProps={{
                          hideFooter: true,
                        }}
                        path={termsAndConditionsRewardsRoute.path}
                        title={termsAndConditionsRewardsRoute.title}
                      />
                      <RouteLayoutPublic
                        component={PageSubscriptionTermsAndConditions}
                        layoutProps={{
                          hideFooter: true,
                        }}
                        path={subscriptionTermsAndConditionsRoute.path}
                        title={subscriptionTermsAndConditionsRoute.title}
                      />
                      <RouteLayoutPublic
                        component={PageReferralTermsAndConditions}
                        layoutProps={{
                          hideFooter: true,
                        }}
                        path={referralTermsAndConditionsRoute.path}
                        title={referralTermsAndConditionsRoute.title}
                      />

                      {/* Main routes */}
                      <RouteLayoutPrivate
                        component={PageHome}
                        exact
                        path={locationRoute.path}
                        title={locationRoute.title}
                        layoutProps={{
                          publicMenuLinkMode: false,
                        }}
                      />
                      <RouteLayoutPrivate
                        component={PageCateringHome}
                        exact
                        path={cateringRoute.path}
                        title={cateringRoute.title}
                        layoutProps={{
                          publicMenuLinkMode: false,
                        }}
                      />
                      <RouteLayoutPrivate
                        component={PageReserveHome}
                        exact
                        path={reserveRoute.path}
                        title={reserveRoute.title}
                        layoutProps={{
                          publicMenuLinkMode: false,
                        }}
                      />
                      <RouteLayoutPrivate
                        component={PagePlaceOrder}
                        exact
                        path={placeOrderRoute.path}
                        title={placeOrderRoute.title}
                        showHeaderLocation={true}
                        showHeaderDeliveryDate={true}
                      />
                      <RouteLayoutPrivate
                        component={PagePlaceCateringOrder}
                        exact
                        path={placeCateringOrderRoute.path}
                        title={placeCateringOrderRoute.title}
                        showHeaderLocation={true}
                        showHeaderDeliveryDate={false}
                      />
                      <RouteLayoutPrivate
                        component={PagePlaceReserveOrder}
                        exact
                        path={placeReserveOrderRoute.path}
                        title={placeReserveOrderRoute.title}
                        showHeaderLocation={true}
                        showHeaderDeliveryDate={false}
                      />
                      <RouteLayoutPrivate
                        component={PagePickCateringDeliveryDateTime}
                        exact
                        path={pickCateringDeliveryDateAndTimeRoute.path}
                        title={pickCateringDeliveryDateAndTimeRoute.title}
                        showHeaderLocation={true}
                      />
                      <RouteLayoutPrivate
                        component={PagePickReserveDeliveryDateTime}
                        exact
                        path={pickReserveDeliveryDateAndTimeRoute.path}
                        title={pickReserveDeliveryDateAndTimeRoute.title}
                        showHeaderLocation={true}
                      />
                      <RouteLayoutPrivate
                        component={PageSetDeliveryReserveFee}
                        exact
                        path={setDeliveryReserveFeeRoute.path}
                        title={setDeliveryReserveFeeRoute.title}
                        showHeaderLocation={true}
                      />
                      <RouteLayoutPrivate
                        component={PageTeamAdmin}
                        path={teamRoute.path}
                        title={teamRoute.title}
                      />
                      <RouteLayoutSidebar
                        component={PageConfirmation}
                        path={confirmationRoute.path}
                        title={confirmationRoute.title}
                      />
                      <RouteLayoutSidebar
                        component={PageOrderHistory}
                        path={historyRoute.path}
                        title={historyRoute.title}
                      />
                      <RouteLayoutPrivate
                        component={PageCheckout}
                        path={checkoutRoute.path}
                        title={checkoutRoute.title}
                      />
                      <RouteLayoutPrivate
                        component={PagePerksCredits}
                        exact
                        path={perksCreditsRoute.path}
                        title={perksCreditsRoute.title}
                      />
                      <RouteLayoutSidebar
                        component={PageProfile}
                        path={[profileRoute.path, unsubscribeRoute.path]}
                        sidebarRight={[ProfileSidebar]}
                        title={profileRoute.title}
                      />
                      <RouteLayoutSidebar
                        component={PageContact}
                        path={contactRoute.path}
                        title={contactRoute.title}
                      />
                      <Logout path={logoutRoute.path} title={logoutRoute.title} />
                      <RouteLayoutPublic
                        component={PageSlack}
                        path={slackRoute.path}
                        title={slackRoute.title}
                      />
                      <RouteLayoutPublic
                        component={PageUI}
                        path={uiRoute.path}
                        title={uiRoute.title}
                      />
                      <RouteLayoutPrivate
                        component={PageUnlimitedDelivery}
                        path={subscriptionsPromoRoute.path}
                        title={subscriptionsPromoRoute.title}
                      />
                      <RouteLayoutPrivate
                        component={PageSavedLocations}
                        path={savedLocationsRoute.path}
                        title={savedLocationsRoute.title}
                      />
                      <RouteLayoutPublic
                        component={PageSurvey}
                        path={[surveyRoute.path, formRoute.path]}
                        exact
                        title={surveyRoute.title}
                        layoutProps={{
                          hideHeader: true,
                          hideFooter: true,
                          flex: true,
                          styles: {
                            backgroundColor: '#f5f5f5',
                          },
                        }}
                      />
                      <Route
                        component={PageLocationSearch}
                        exact
                        path={locationSearchRoute.path}
                        title={locationSearchRoute.title}
                      />

                      {/* Redirect routes */}
                      <RouteWithGlobalLoader
                        component={HomeRedirect}
                        exact
                        path={rootRoute.path}
                        title={rootRoute.title}
                      />
                      <RouteWithGlobalLoader
                        component={HomeRedirect}
                        exact
                        path={homeRoute.path}
                        title={homeRoute.title}
                      />
                      <RouteWithGlobalLoader
                        component={PageTeamInvite}
                        path={teamInviteRoute.path}
                        title={teamInviteRoute.title}
                      />
                      <RouteWithGlobalLoader
                        component={PageGiftRedemption}
                        path={redeemGiftRoute.path}
                        title={redeemGiftRoute.title}
                      />
                      <Route // Handle Branch referral link
                        component={props => (
                          <Redirect to={`${registerRoute.path}${props.location.search}`} />
                        )}
                        path={branchReferralRoute.path}
                        title={branchReferralRoute.title}
                      />
                      <Route
                        component={() => <Redirect to={friendsRoute.path} />}
                        path={referralsRoute.path}
                        title={referralsRoute.title}
                      />
                      <Route // Route used for managing subscription. Used by mobile.
                        component={() => <Redirect to={profileRoute.path} />}
                        path={subscriptionsRoute.path}
                        title={subscriptionsRoute.title}
                      />

                      {/* Deprecated routes */}
                      <Route // Dedicated page retired 04-22-2020 - replaced with PageRegister
                        component={props => (
                          <Redirect to={`${registerRoute.path}${props.location.search}`} />
                        )}
                        path={referralRoute.path}
                        title={referralRoute.title}
                      />
                      <Route // Dedicated page retired 04-22-2020 - replaced with PageRegister
                        component={props => (
                          <Redirect
                            to={`${registerRoute.path}?completeAccountToken=${props.match.params.token}`}
                          />
                        )}
                        path={completeAccountRoute.path}
                        title={completeAccountRoute.title}
                      />
                      <RouteWithGlobalLoader // Dedicated page retired 07-06-2020 - Only runs search and redirects to Home
                        component={PageSelectLocation}
                        path={selectLocationRoute.path}
                        title={selectLocationRoute.title}
                      />
                      <Route exact path="/credits">
                        {<Redirect to={perksCreditsRoute.path} />}
                      </Route>

                      {/* Error pages */}
                      <RouteLayoutPublic
                        path={maintenanceRoute.path}
                        component={PageMaintenance}
                        title={maintenanceRoute.title}
                        layoutProps={{
                          childrenContainer: Fragment,
                        }}
                      />
                      <RouteLayoutPublic
                        path={errorRoute.path}
                        component={PageError}
                        title={errorRoute.title}
                        layoutProps={{
                          childrenContainer: Fragment,
                        }}
                      />

                      {/* This one goes last */}
                      <RouteLayoutPublic
                        layoutProps={{
                          childrenContainer: Fragment,
                        }}
                        component={PageNotFound}
                        title="Not found"
                      />
                    </Switch>
                  </ConnectedRouter>
                  <Snackbars />
                </Provider>
              </SnackbarProvider>
            </ThemeProvider>
          </Elements>
        </ThemeProviderStyled>,
        root,
      )
    }
  } catch (ex) {
    logException(ex)
  }
}

// REDIRECT OLD URLS HERE
if (window.location.pathname.indexOf('/home/') > -1) {
  window.location = window.location.pathname.replace('/home', '')
} else if (!inIframe()) {
  renderApp()
}
