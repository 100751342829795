import { createSelector } from 'reselect'
import { ROWS_PER_PAGE } from '../../components/teams/admin/adminUtils'
import { getComparator, stableSort } from './utils/sort'

export const selectInvoices = createSelector(
  [state => state.teamBilling.invoices],
  invoices => invoices,
)

export const selectGiftTransactions = createSelector(
  [state => state.teamBilling.giftTransactions],
  giftTransactions => giftTransactions,
)

export const selectGiftTransactionDetails = createSelector(
  [state => state.teamBilling.giftTransactionDetails],
  giftTransactionDetails => giftTransactionDetails,
)

export const selectGiftRecipientsRows = createSelector(
  [
    selectGiftTransactionDetails,
    state => state.teamBilling.sortGiftRecipients,
    state => state.teamBilling.pageGiftRecipients,
  ],
  (giftTransactionDetails, sort, page) =>
    stableSort(giftTransactionDetails.recipients, getComparator(sort.field, sort.direction)).slice(
      page * ROWS_PER_PAGE,
      page * ROWS_PER_PAGE + ROWS_PER_PAGE,
    ),
)
