import { Box, Typography } from '@material-ui/core'
import React from 'react'

const HomeAd = ({ title, image, description, actions }) => {
  return (
    <Box paddingX={3} width="240px">
      <Box width="100%" textAlign="center" marginBottom={3}>
        {image}
      </Box>

      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      <Typography gutterBottom>{description}</Typography>

      <Box display="flex" flexDirection="column" marginTop={3}>
        {actions}
      </Box>
    </Box>
  )
}

export default HomeAd
