import { differenceInCalendarDays } from 'date-fns'
import * as Cookies from 'js-cookie'
import { all, call, put, select, take } from 'redux-saga/effects'

import { api, API_MONO, loadIsGroupOrderLocation, loadLocationById } from '../../api/api'
import { BRAZE_CUSTOM_EVENTS, publishBrazeEvent } from '../../util/braze'
import { normalizeLocationErrorResponse } from '../../util/errorUtils'
import { setGTMBuilding } from '../../util/gtmUtils'
import { SELECTED_KIOSK } from './locationSearch'
import { apiSaga } from './sagas'
import {
  selectCurrentLocationWithAddress,
  selectCurrentUser,
  selectLocationDeliveryId,
  selectUserLocationId,
} from './selectors'
import { updateAndSaveUserLocationStart } from './user'

// ------------------------------------
// Action Types & Creators
// ------------------------------------

export const LOAD_LOCATION = 'foodsby/location/LOAD_LOCATION'
export const LOAD_LOCATION_SUCCESS = 'foodsby/location/LOAD_LOCATION_SUCCESS'
export const LOAD_LOCATION_FAILURE = 'foodsby/location/LOAD_LOCATION_FAILURE'
export const LOAD_PREVIOUS_LOCATION = 'foodsby/location/LOAD_PREVIOUS_LOCATION'
export const LOAD_PREVIOUS_LOCATION_SUCCESS = 'foodsby/location/LOAD_PREVIOUS_LOCATION_SUCCESS'
export const LOAD_PREVIOUS_LOCATION_FAILURE = 'foodsby/location/LOAD_PREVIOUS_LOCATION_FAILURE'
export const CLEAR_PREVIOUS_LOCATION = 'foodsby/location/CLEAR_PREVIOUS_LOCATION'
export const LOAD_IS_GROUP_ORDER_LOCATION = 'foodsby/location/LOAD_IS_GROUP_ORDER_LOCATION'
export const LOAD_IS_GROUP_ORDER_LOCATION_SUCCESS =
  'foodsby/location/LOAD_IS_GROUP_ORDER_LOCATION_SUCCESS'
export const LOAD_IS_GROUP_ORDER_LOCATION_FAILURE =
  'foodsby/location/LOAD_IS_GROUP_ORDER_LOCATION_FAILURE'

export const LOAD_USER_SAVED_LOCATIONS = 'foodsby/location/GET_USER_SAVED_LOCATIONS'
export const LOAD_USER_SAVED_LOCATIONS_SUCCESS = 'foodsby/location/GET_USER_SAVED_LOCATIONS_SUCCESS'
export const LOAD_USER_SAVED_LOCATIONS_FAILURE = 'foodsby/location/GET_USER_SAVED_LOCATIONS_FAILURE'

export const CREATE_USER_SAVED_LOCATION = 'foodsby/location/CREATE_USER_SAVED_LOCATION'
export const CREATE_USER_SAVED_LOCATION_SUCCESS =
  'foodsby/location/CREATE_USER_SAVED_LOCATION_SUCCESS'
export const CREATE_USER_SAVED_LOCATION_FAILURE =
  'foodsby/location/CREATE_USER_SAVED_LOCATION_FAILURE'

export const UPDATE_USER_SAVED_LOCATION = 'foodsby/location/UPDATE_USER_SAVED_LOCATION'
export const UPDATE_USER_SAVED_LOCATION_SUCCESS =
  'foodsby/location/UPDATE_USER_SAVED_LOCATION_SUCCESS'
export const UPDATE_USER_SAVED_LOCATION_FAILURE =
  'foodsby/location/UPDATE_USER_SAVED_LOCATION_FAILURE'

export const DELETE_USER_SAVED_LOCATION = 'foodsby/location/DELETE_USER_SAVED_LOCATION'
export const DELETE_USER_SAVED_LOCATION_SUCCESS =
  'foodsby/location/DELETE_USER_SAVED_LOCATION_SUCCESS'
export const DELETE_USER_SAVED_LOCATION_FAILURE =
  'foodsby/location/DELETE_USER_SAVED_LOCATION_FAILURE'

export const CLEAR_LOCATION_ERRORS = 'foodsby/location/CLEAR_LOCATION_ERRORS'
export const CLEAR_LOCATION = 'foodsby/location/CLEAR_LOCATION'

export const TOGGLE_DELIVERY_INSTRUCTION_DIALOG =
  'foodsby/location/TOGGLE_DELIVERY_INSTRUCTION_DIALOG'

export const loadLocationStart = locationId => {
  return {
    payload: { locationId },
    type: LOAD_LOCATION,
  }
}

export const loadLocationSuccess = location => {
  return {
    payload: { location },
    type: LOAD_LOCATION_SUCCESS,
  }
}

export const loadLocationFailure = error => {
  return {
    error,
    type: LOAD_LOCATION_FAILURE,
  }
}

export const loadPreviousLocationStart = locationId => {
  return {
    payload: { locationId },
    type: LOAD_PREVIOUS_LOCATION,
  }
}

export const loadPreviousLocationSuccess = previousLocation => {
  return {
    payload: { previousLocation },
    type: LOAD_PREVIOUS_LOCATION_SUCCESS,
  }
}

export const loadPreviousLocationFailure = error => {
  return {
    error,
    type: LOAD_PREVIOUS_LOCATION_FAILURE,
  }
}

export const clearLocationErrors = () => {
  return {
    type: CLEAR_LOCATION_ERRORS,
  }
}

export const clearPreviousLocation = () => {
  return {
    type: CLEAR_PREVIOUS_LOCATION,
  }
}

export const loadIsGroupOrderLocationStart = locationId => {
  return {
    payload: { locationId },
    type: LOAD_IS_GROUP_ORDER_LOCATION,
  }
}

export const loadIsGroupOrderLocationSuccess = response => {
  return {
    payload: { response },
    type: LOAD_IS_GROUP_ORDER_LOCATION_SUCCESS,
  }
}

export const loadIsGroupOrderLocationFailure = error => {
  return {
    error,
    type: LOAD_IS_GROUP_ORDER_LOCATION_FAILURE,
  }
}

export const loadUserSavedLocationsStart = () => {
  return {
    type: LOAD_USER_SAVED_LOCATIONS,
  }
}

export const loadUserSavedLocationsSuccess = response => {
  return {
    payload: { response },
    type: LOAD_USER_SAVED_LOCATIONS_SUCCESS,
  }
}

export const loadUserSavedLocationsFailure = error => {
  return {
    error,
    type: LOAD_USER_SAVED_LOCATIONS_FAILURE,
  }
}

export const createUserSavedLocationStart = location => {
  return {
    payload: { location },
    type: CREATE_USER_SAVED_LOCATION,
  }
}

export const createUserSavedLocationSuccess = response => {
  return {
    payload: { response },
    type: CREATE_USER_SAVED_LOCATION_SUCCESS,
  }
}

export const createUserSavedLocationFailure = error => {
  return {
    error,
    type: CREATE_USER_SAVED_LOCATION_FAILURE,
  }
}

export const updateUserSavedLocationStart = (savedLocationId, name, deliveryInstructions) => {
  return {
    payload: { deliveryInstructions, name, savedLocationId },
    type: UPDATE_USER_SAVED_LOCATION,
  }
}

export const updateUserSavedLocationSuccess = response => {
  return {
    payload: { response },
    type: UPDATE_USER_SAVED_LOCATION_SUCCESS,
  }
}

export const updateUserSavedLocationFailure = error => {
  return {
    error,
    type: UPDATE_USER_SAVED_LOCATION_FAILURE,
  }
}

export const deleteUserSavedLocationStart = savedLocationId => {
  return {
    payload: { savedLocationId },
    type: DELETE_USER_SAVED_LOCATION,
  }
}

export const deleteUserSavedLocationSuccess = response => {
  return {
    payload: { response },
    type: DELETE_USER_SAVED_LOCATION_SUCCESS,
  }
}

export const deleteUserSavedLocationFailure = error => {
  return {
    error,
    type: DELETE_USER_SAVED_LOCATION_FAILURE,
  }
}

export const toggleDeliveryInstructionsDialog = () => ({
  type: TOGGLE_DELIVERY_INSTRUCTION_DIALOG,
})

export const clearLocation = () => ({
  type: CLEAR_LOCATION,
})

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [CLEAR_LOCATION]: state => ({
    ...state,
    location: undefined,
  }),
  [CLEAR_LOCATION_ERRORS]: state => {
    return {
      ...state,
      savedLocationError: undefined,
      createSavedLocationError: undefined,
      updateSavedLocationError: undefined,
      deletingSavedLocationError: undefined,
    }
  },
  [CLEAR_PREVIOUS_LOCATION]: state => {
    return {
      ...state,
      previousLocation: undefined,
    }
  },
  [CREATE_USER_SAVED_LOCATION]: state => {
    return {
      ...state,
      isCreatingSavedLocation: true,
    }
  },
  [CREATE_USER_SAVED_LOCATION_FAILURE]: state => {
    return {
      ...state,
      createSavedLocationError: 'Something went wrong when saving this location to your profile.',
      isCreatingSavedLocation: false,
    }
  },
  [CREATE_USER_SAVED_LOCATION_SUCCESS]: state => {
    return {
      ...state,
      createSavedLocationError: undefined,
      isCreatingSavedLocation: false,
    }
  },
  [DELETE_USER_SAVED_LOCATION]: state => {
    return {
      ...state,
      isDeletingSavedLocation: true,
    }
  },
  [DELETE_USER_SAVED_LOCATION_SUCCESS]: state => {
    return {
      ...state,
      isDeletingSavedLocation: false,
    }
  },
  [DELETE_USER_SAVED_LOCATION_SUCCESS]: (state, action) => {
    const { error } = action
    return {
      ...state,
      deletingSavedLocationError: error,
      isDeletingSavedLocation: false,
    }
  },
  [LOAD_IS_GROUP_ORDER_LOCATION]: state => {
    return {
      ...state,
      isGroupOrderLocationLoading: true,
    }
  },
  [LOAD_IS_GROUP_ORDER_LOCATION_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      isGroupOrderError: error,
      isGroupOrderLocation: undefined,
      isGroupOrderLocationLoading: false,
    }
  },
  [LOAD_IS_GROUP_ORDER_LOCATION_SUCCESS]: (state, action) => {
    const {
      payload: { response },
    } = action

    return {
      ...state,
      isGroupOrderLocation: response.supported,
      isGroupOrderLocationLoading: false,
    }
  },
  [LOAD_LOCATION]: state => {
    return {
      ...state,
      isLocationLoading: true,
    }
  },
  [LOAD_LOCATION_FAILURE]: (state, action) => {
    const error = normalizeLocationErrorResponse(action.error)
    return {
      ...state,
      isLocationLoading: false,
      locationInitialized: true,
      location: undefined,
      locationError: error,
    }
  },
  [LOAD_LOCATION_SUCCESS]: (state, action) => {
    const {
      payload: { location },
    } = action
    return {
      ...state,
      isLocationLoading: false,
      locationInitialized: true,
      location,
      locationError: undefined,
    }
  },
  [LOAD_PREVIOUS_LOCATION]: state => {
    return {
      ...state,
      isLocationLoading: true,
    }
  },
  [LOAD_PREVIOUS_LOCATION_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      error,
      isLocationLoading: false,
      previousLocation: undefined,
    }
  },
  [LOAD_PREVIOUS_LOCATION_SUCCESS]: (state, action) => {
    const {
      payload: { previousLocation },
    } = action
    previousLocation.address = {
      city: previousLocation.city,
      state: previousLocation.state,
      street: previousLocation.street,
      zip: previousLocation.zip,
    }
    return {
      ...state,
      error: undefined,
      isLocationLoading: false,
      previousLocation,
    }
  },
  [LOAD_USER_SAVED_LOCATIONS]: state => {
    return {
      ...state,
      isSavedLocationsLoading: true,
    }
  },
  [LOAD_USER_SAVED_LOCATIONS_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      isSavedLocationsLoading: false,
      savedLocationError: error,
      savedLocations: undefined,
    }
  },
  [LOAD_USER_SAVED_LOCATIONS_SUCCESS]: (state, action) => {
    const {
      payload: { response },
    } = action
    return {
      ...state,
      isSavedLocationsLoading: false,
      savedLocations: response,
    }
  },
  [TOGGLE_DELIVERY_INSTRUCTION_DIALOG]: state => ({
    ...state,
    showDeliveryInstructionsDialog: !state?.showDeliveryInstructionsDialog,
  }),
  [UPDATE_USER_SAVED_LOCATION]: state => {
    return {
      ...state,
      isUpdatingSavedLocation: true,
    }
  },
  [UPDATE_USER_SAVED_LOCATION_FAILURE]: state => {
    return {
      ...state,
      isUpdatingSavedLocation: false,
      updateSavedLocationError: 'Something went wrong when updating this location on your profile.',
    }
  },
  [UPDATE_USER_SAVED_LOCATION_SUCCESS]: state => {
    return {
      ...state,
      isUpdatingSavedLocation: false,
      showDeliveryInstructionsDialog: false,
      updateSavedLocationError: undefined,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  isLocationLoading: false,
  location: undefined,
  locationInitialized: false,
}

export default function location(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------

export function* watchLoadLocation() {
  while (true) {
    const {
      payload: { locationId },
    } = yield take(LOAD_LOCATION)
    const location = yield select(selectCurrentLocationWithAddress)
    if (!location || location.deliveryLocationId !== locationId) {
      yield call(
        apiSaga,
        loadLocationById,
        [locationId],
        loadLocationSuccess,
        loadLocationFailure,
        undefined,
      )
      yield put(loadIsGroupOrderLocationStart(locationId))
    } else {
      yield put(loadLocationSuccess(location))
    }
  }
}

export function* watchLoadLocationSuccess() {
  while (true) {
    const {
      payload: { location },
    } = yield take(LOAD_LOCATION_SUCCESS)

    Cookies.set(
      'activeMinThirtyDays',
      differenceInCalendarDays(Date.now(), location.activationDate) > 30,
    )
    setGTMBuilding('location-loaded', location)
  }
}

export function* watchLoadLocationFailure() {
  while (true) {
    const { error } = yield take(LOAD_LOCATION_FAILURE)
    if (Number(error.code) === 404) {
      window.location.href = process.env.REACT_APP_MARKETING_URL
    }
  }
}

export function* watchSelectedKiosk() {
  while (true) {
    const [
      // eslint-disable-next-line
      kiosk,
      {
        payload: { location },
      },
    ] = yield all([take(SELECTED_KIOSK), take(LOAD_LOCATION_SUCCESS)])
    setGTMBuilding('searchedBuilding', location)
  }
}

export function* watchLoadPreviousLocation() {
  while (true) {
    const {
      payload: { locationId },
    } = yield take(LOAD_PREVIOUS_LOCATION)
    const currentLocationId = yield select(selectLocationDeliveryId)
    yield call(
      apiSaga,
      loadLocationById,
      [locationId],
      loadPreviousLocationSuccess,
      loadPreviousLocationFailure,
      undefined,
      true,
    )
    yield put(loadIsGroupOrderLocationStart(currentLocationId))
  }
}
export function* watchLoadGroupOrderLocation() {
  while (true) {
    const {
      payload: { locationId },
    } = yield take(LOAD_IS_GROUP_ORDER_LOCATION)
    try {
      yield call(
        apiSaga,
        loadIsGroupOrderLocation,
        [locationId],
        loadIsGroupOrderLocationSuccess,
        loadIsGroupOrderLocationFailure,
      )
    } catch (ex) {
      yield put(loadIsGroupOrderLocationFailure(ex))
    }
  }
}

export function* watchLoadIsGroupOrderLocationSuccess() {
  while (true) {
    const {
      payload: {
        response: { groupOrderOnly, supported, urlFriendlyName },
      },
    } = yield take(LOAD_IS_GROUP_ORDER_LOCATION_SUCCESS)

    if (supported) {
      publishBrazeEvent(BRAZE_CUSTOM_EVENTS.GO_ELIGIBLE)
      if (groupOrderOnly) {
        window.location.href = `${process.env.REACT_APP_GROUP_ORDER_URL}/location/${urlFriendlyName}`
      }
    }
  }
}

export function* watchLoadUserSavedLocations() {
  while (true) {
    yield take(LOAD_USER_SAVED_LOCATIONS)
    yield call(
      apiSaga,
      getSavedLocations,
      [],
      loadUserSavedLocationsSuccess,
      loadUserSavedLocationsFailure,
    )
  }
}

export function* watchLoadUserSavedLocationsSuccess() {
  while (true) {
    const [
      {
        payload: { response },
      },
      {
        payload: { location },
      },
    ] = yield all([take(LOAD_USER_SAVED_LOCATIONS_SUCCESS), take(LOAD_LOCATION_SUCCESS)])
    const userLocationId = yield select(selectUserLocationId)

    // If location is not the user's default location but it's in their saved locations
    // Set that location as their default
    if (userLocationId !== location.deliveryLocationId) {
      if (
        response?.find(
          savedLocation => savedLocation.deliveryLocationId === location?.deliveryLocationId,
        )
      ) {
        yield put(updateAndSaveUserLocationStart(location.deliveryLocationId))
      }
    }
  }
}

export function* watchCreateUserSavedLocation() {
  while (true) {
    const {
      payload: { location },
    } = yield take(CREATE_USER_SAVED_LOCATION)

    yield call(
      apiSaga,
      createSavedLocation,
      [location.deliveryLocationId, location?.deliveryLocationName],
      createUserSavedLocationSuccess,
      createUserSavedLocationFailure,
    )

    const currentUser = yield select(selectCurrentUser)
    if (currentUser.deliveryLocationId !== location.deliveryLocationId) {
      yield put(updateAndSaveUserLocationStart(location.deliveryLocationId))
    }

    yield put(loadUserSavedLocationsStart())
  }
}

export function* watchUpdateUserSavedLocation() {
  while (true) {
    const {
      payload: { deliveryInstructions, name, savedLocationId },
    } = yield take(UPDATE_USER_SAVED_LOCATION)

    yield call(
      apiSaga,
      updateSavedLocation,
      [savedLocationId, name, deliveryInstructions],
      updateUserSavedLocationSuccess,
      updateUserSavedLocationFailure,
    )

    yield put(loadUserSavedLocationsStart())
  }
}

export function* watchDeleteUserSavedLocation() {
  while (true) {
    const {
      payload: { savedLocationId },
    } = yield take(DELETE_USER_SAVED_LOCATION)

    yield call(
      apiSaga,
      deleteSavedLocation,
      [savedLocationId],
      deleteUserSavedLocationSuccess,
      deleteUserSavedLocationFailure,
    )

    yield put(loadUserSavedLocationsStart())
  }
}

// ------------------------------------
// APIs
// ------------------------------------

export const getSavedLocations = () => api.get(`${API_MONO}/user/saved-locations`)
export const createSavedLocation = (locationId, name) =>
  api.post(`${API_MONO}/user/saved-locations`, { locationId, name })
export const updateSavedLocation = (savedLocationId, name, deliveryInstructions) =>
  api.patch(`${API_MONO}/user/saved-locations`, { deliveryInstructions, name, savedLocationId })
export const deleteSavedLocation = savedLocationId =>
  api.delete(`${API_MONO}/user/saved-locations/${savedLocationId}`)
