import { groupBy, isEmpty } from 'lodash'
import currency from 'currency.js'
import pluralize from 'pluralize'
import { formatCentsToPrettyDollars } from '../perks/giftCardUtils'

// ------------------------------------
// Constants
// ------------------------------------
export const ROWS_PER_PAGE = 10
export const MEMBER_ROLES = Object.freeze({
  ADMIN: 'Admin',
  MEMBER: 'Member',
})

// ------------------------------------
// Methods
// ------------------------------------
export const getFullName = ({ firstName, lastName }) => {
  if (!firstName && !lastName) {
    return undefined
  }

  return `${firstName} ${lastName}`
}

export const formatAllMemberNames = members =>
  members.map(m => getFullName(m) ?? getEmail(m)).join(', ')

// The reason we need to check .email and .username is because of the inconsistency in the API.
// The team members have it as email whereas program members have it as username.
export const getEmail = member => member.email ?? member.username

export const getMemberRole = isAdmin => (isAdmin ? MEMBER_ROLES.ADMIN : MEMBER_ROLES.MEMBER)

export const isTeamAdmin = (externalManagers, userId) =>
  externalManagers?.some(manager => manager.id === userId)

export const shouldDisplayInvoice = invoice =>
  invoice.status.toUpperCase() === 'FINAL' || invoice.status.toUpperCase() === 'PAID'

export const isInvoicePaid = status => status.toUpperCase() === 'PAID'
export const isInvoiceCanceled = status => status.toUpperCase() === 'CANCELED'

export const isRowSelected = (selectedMembers, member) =>
  selectedMembers.map(sm => sm.userId).includes(member.userId)

export const getSelectionDetails = (allMembers, rowsOnPage, selectedMembers) => {
  const allSelected = isAllSelected(allMembers, selectedMembers)
  const allPageSelected = isAllSelected(rowsOnPage, selectedMembers) || allSelected
  const someSelected = isSomeSelected(selectedMembers.length, allMembers.length) || allPageSelected
  const someSelectedOnPage = isSomeSelectedOnPage(rowsOnPage, selectedMembers)

  return {
    allSelected,
    allPageSelected,
    someSelected,
    someSelectedOnPage,
  }
}

export const formatOneWordEnumToPascal = word =>
  `${word.substring(0, 1).toUpperCase()}${word.substring(1).toLowerCase()}`

// Usage Type | Discount Amount | Member Count | Auto-Enrolled Status (if true)
export const formatProgramOverview = program =>
  `${formatOneWordEnumToPascal(program.usageType)}` +
  ' | ' +
  `${currency(program.discountAmountInPennies / 100, { formatWithSymbol: true }).format()}` +
  ' | ' +
  `${program.userIds.length} ${pluralize('Member', program.userIds.length)}` +
  `${program.autoEnrollNewMembers ? ' | Auto-enrolling new members' : ''}`

export const isPaymentMethodRequiredForTeamPerks = (
  is100PercentOffer,
  teamPaymentCollectionMethod,
) => !is100PercentOffer && teamPaymentCollectionMethod === 'AUTOMATIC'

export const getGiftTransactionTotal = details => {
  const total = details.discountAmountInPennies
    ? formatCentsToPrettyDollars(details.totalGiftAmount - details.discountAmountInPennies)
    : formatCentsToPrettyDollars(details.totalGiftAmount)
  return total
}

// ------------------------------------
// Private methods
// ------------------------------------
const isAllSelected = (rows, selectedMembers) => {
  if (isEmpty(rows) || isEmpty(selectedMembers)) {
    return false
  }

  const groupedRowsOnPage = groupBy(rows, 'userId')
  const groupedSelectedMembers = groupBy(selectedMembers, 'userId')

  return Object.keys(groupedRowsOnPage).every(rowKey => groupedSelectedMembers[rowKey])
}

const isSomeSelected = (numSelected, totalRows) => numSelected > 0 && numSelected < totalRows

const isSomeSelectedOnPage = (rowsOnPage, selectedMembers) => {
  const groupedRowsOnPage = groupBy(rowsOnPage, 'userId')
  const groupedSelectedMembers = groupBy(selectedMembers, 'userId')

  return Object.keys(groupedRowsOnPage).some(rowKey => groupedSelectedMembers[rowKey])
}
