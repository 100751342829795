import { Link } from '@material-ui/core'
import { format, getDay } from 'date-fns'
import React from 'react'

import HowItWorks from './HowItWorks'
import PreferMobile from './PreferMobile'
import RestaurantShowcase from './RestaurantShowcase'

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const UnderSixDays = ({
  JoinForm,
  activationDate,
  isAuthenticated,
  locationId,
  onChangeDay,
  restaurantLogos,
}) => {
  const SERVING_LOCATION_ON = getDay(activationDate)
  return (
    <div className="row center-xs">
      <div className="col-xs-12 coming-soon-content">
        <h4>
          Foodsby starts on{' '}
          <Link
            id="coming-soon-day-button"
            onClick={() => onChangeDay(format(activationDate, 'YYYYMMDD'), locationId)}
            style={{ fontSize: '34px' }}
          >
            {daysOfWeek[SERVING_LOCATION_ON]},
          </Link>
          {format(activationDate, ' MMMM Do')}!
        </h4>
        <p>
          {isAuthenticated
            ? 'Thanks for creating your account. You will receive an email on the first morning when Foodsby becomes available.'
            : 'Sign up today to see which restaurant options are available and pre-order your first meal now.'}
        </p>
        {!isAuthenticated && (
          <div className="row center-xs">
            <div className="col-xs-8">
              <JoinForm locationId={locationId} />
            </div>
          </div>
        )}
        <hr className="coming-soon-hr" />
        <HowItWorks />
        <hr className="coming-soon-hr" />
        <PreferMobile />
        <hr className="coming-soon-hr" />
        <RestaurantShowcase restaurantLogos={restaurantLogos} />
      </div>
    </div>
  )
}

export default UnderSixDays
