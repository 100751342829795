import {
  rootRoute,
  historyRoute,
  logoutRoute,
  profileRoute,
  perksCreditsRoute,
  savedLocationsRoute,
} from '../../../routes/routes'

const menuItems = {
  orderingMenu: [
    {
      copy: 'Home',
      href: rootRoute.path,
    },
    {
      copy: 'Group Ordering',
      href: `${process.env.REACT_APP_GROUP_ORDER_URL}`,
      external: true,
      newTab: true,
    },
  ],
  accountMenu: [
    {
      copy: 'Profile',
      href: profileRoute.path,
    },
    {
      copy: 'Perks & Rewards',
      href: perksCreditsRoute.path,
    },
    {
      copy: 'Order History',
      href: historyRoute.path,
    },
    {
      copy: 'Locations',
      href: savedLocationsRoute.path,
    },
  ],

  adminMenu: [
    {
      copy: 'Foodsby Admin',
      href: `${process.env.REACT_APP_NEW_FOODSBY_ADMIN_URL}`,
      external: true,
    },
    {
      copy: 'Foodsby Admin (Old)',
      href: `${process.env.REACT_APP_FOODSBY_BASE_URL}/admin/login`,
      external: true,
    },
  ],
  logout: [
    {
      copy: 'Sign Out',
      href: logoutRoute.path,
    },
  ],
}

export default menuItems
