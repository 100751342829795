import { call, select, take } from 'redux-saga/effects'

import { attemptChangePassword, deletePaymentOption, loadPaymentOptions } from '../../api/api'
import { getUserAttributes, updateUserAttributes } from '../../services/onboarding'
import {
  getUserPrivacy,
  loadProfileByToken,
  patchUserProfile,
  updateProfilePicture,
  updateUserPrivacy,
  getUserEmailPreferenceCenter,
} from '../../services/profile'
import { loginUser } from '../../services/user'
import { createAction, createAsyncAction, FULFILLED, PENDING, REJECTED } from '../utils'
import { apiSaga, formSaga } from './sagas'
import { selectCurrentUser, selectProfile } from './selectors'
import { loadPotentialTeamsStart } from './teamOnboarding'
import { currentUserStart } from './user'

// ------------------------------------
// Action Types & Creators
// ------------------------------------
export const PROFILE_SET = 'foodsby/profile/PROFILE_SET'
export const PROFILE_ATTRIBUTES_UPDATE = 'foodsby/profile/PROFILE_UPDATE'
export const PERSONAL_INFO_SET = 'foodsby/profile/PERSONAL_INFO_SET'
export const PROFILE_ATTRIBUTES_SET = 'foodsby/profile/PROFILE_ATTRIBUTES_SET'
export const EMAIL_PREFERENCE_URL_SET = 'foodsby/profile/EMAIL_PREFERENCE_URL_SET'
export const PASSWORD_UPDATE = 'foodsby/profile/PASSWORD_UPDATE'
export const PERSONAL_INFO_EDIT_MODE_SET = 'foodsby/profile/PERSONAL_INFO_EDIT_MODE_SET'
export const PASSWORD_EDIT_MODE_SET = 'foodsby/profile/PASSWORD_EDIT_MODE_SET'

export const LOAD_PAYMENT_OPTIONS = 'foodsby/profile/LOAD_PAYMENT_OPTIONS'
export const LOAD_PAYMENT_OPTIONS_SUCCESS = 'foodsby/profile/LOAD_PAYMENT_OPTIONS_SUCCESS'
export const LOAD_PAYMENT_OPTIONS_FAILURE = 'foodsby/profile/LOAD_PAYMENT_OPTIONS_FAILURE'

export const DELETE_PAYMENT_OPTION = 'foodsby/profile/DELETE_PAYMENT_OPTION'
export const DELETE_PAYMENT_OPTION_SUCCESS = 'foodsby/profile/DELETE_PAYMENT_OPTION_SUCCESS'
export const DELETE_PAYMENT_OPTION_FAILURE = 'foodsby/profile/DELETE_PAYMENT_OPTION_FAILURE'

export const GET_USER_PRIVACY = 'foodsby/user/GET_USER_PRIVACY'
export const GET_USER_PRIVACY_SUCCESS = 'foodsby/user/GET_USER_PRIVACY_SUCCESS'
export const GET_USER_PRIVACY_FAILURE = 'foodsby/user/GET_USER_PRIVACY_FAILURE'

export const UPDATE_USER_PRIVACY = 'foodsby/user/UPDATE_USER_PRIVACY'
export const UPDATE_USER_PRIVACY_SUCCESS = 'foodsby/user/UPDATE_USER_PRIVACY_SUCCESS'
export const UPDATE_USER_PRIVACY_FAILURE = 'foodsby/user/UPDATE_USER_PRIVACY_FAILURE'

export const UPDATE_PROFILE_PICTURE = 'foodsby/user/UPDATE_PROFILE_PICTURE'
export const UPDATE_PROFILE_PICTURE_SUCCESS = 'foodsby/user/UPDATE_PROFILE_PICTURE_SUCCESS'
export const UPDATE_PROFILE_PICTURE_FAILURE = 'foodsby/user/UPDATE_PROFILE_PICTURE_FAILURE'

export const setProfile = createAsyncAction(PROFILE_SET)
export const setPersonalInfoPending = createAction(PENDING(PERSONAL_INFO_SET))
export const setPersonalInfoFulfilled = createAction(FULFILLED(PERSONAL_INFO_SET))
export const setPersonalInfoRejected = createAction(REJECTED(PERSONAL_INFO_SET))
export const updatePassword = createAsyncAction(PASSWORD_UPDATE)
export const setPersonalInfoEditMode = createAction(PERSONAL_INFO_EDIT_MODE_SET)
export const setPasswordEditMode = createAction(PASSWORD_EDIT_MODE_SET)
export const setProfileAttributes = createAsyncAction(PROFILE_ATTRIBUTES_SET)
export const setUserEmailPreferenceCenter = createAsyncAction(EMAIL_PREFERENCE_URL_SET)
export const updateProfileAttributes = createAsyncAction(PROFILE_ATTRIBUTES_UPDATE)

export const loadPaymentOptionsStart = () => {
  return {
    type: LOAD_PAYMENT_OPTIONS,
  }
}

export const loadPaymentOptionsSuccess = paymentOptions => {
  return {
    payload: { paymentOptions },
    type: LOAD_PAYMENT_OPTIONS_SUCCESS,
  }
}

export const loadPaymentOptionsFailure = error => {
  return {
    error,
    type: LOAD_PAYMENT_OPTIONS_FAILURE,
  }
}

export const deletePaymentOptionStart = ccProfileId => {
  return {
    payload: { ccProfileId },
    type: DELETE_PAYMENT_OPTION,
  }
}

export const deletePaymentOptionSuccess = () => {
  return {
    type: DELETE_PAYMENT_OPTION_SUCCESS,
  }
}

export const deletePaymentOptionFailure = error => {
  return {
    error,
    type: DELETE_PAYMENT_OPTION_FAILURE,
  }
}

export const getUserPrivacyStart = () => {
  return {
    type: GET_USER_PRIVACY,
  }
}

export const getUserPrivacySuccess = privacy => {
  return {
    payload: { privacy },
    type: GET_USER_PRIVACY_SUCCESS,
  }
}

export const getUserPrivacyFailure = err => {
  return {
    error: err,
    type: GET_USER_PRIVACY_FAILURE,
  }
}

export const updateUserPrivacyStart = userPrivacy => {
  return {
    payload: { userPrivacy },
    type: UPDATE_USER_PRIVACY,
  }
}

export const updateUserPrivacySuccess = userPrivacy => {
  return {
    payload: { userPrivacy },
    type: UPDATE_USER_PRIVACY_SUCCESS,
  }
}

export const updateUserPrivacyFailure = err => {
  return {
    error: err,
    type: UPDATE_USER_PRIVACY_FAILURE,
  }
}

export const updateProfilePictureStart = image => {
  return {
    payload: { image },
    type: UPDATE_PROFILE_PICTURE,
  }
}

export const updateProfilePictureSuccess = response => {
  return {
    payload: { response },
    type: UPDATE_PROFILE_PICTURE_SUCCESS,
  }
}

export const updateProfilePictureFailure = err => {
  return {
    error: err,
    type: UPDATE_PROFILE_PICTURE_FAILURE,
  }
}

// ------------------------------------
// Thunks
// ------------------------------------
export const loadProfileStart = () => dispatch => {
  return dispatch(setProfile(loadProfileByToken()))
}

export const updatePersonalInfoStart = (personalInfo, password) => {
  return async (dispatch, getState) => {
    const state = getState()
    const { email, userId } = selectCurrentUser(state)
    const didChangeEmail = Boolean(password)

    try {
      dispatch(setPersonalInfoPending())
      if (didChangeEmail) {
        // Verify that the user entered the correct password
        await loginUser({ password, userName: email })
      }
      // Update the user's profile
      await patchUserProfile(userId, personalInfo)
      if (didChangeEmail) {
        // Re authenticate the user with the new email address
        await loginUser({ password, userName: personalInfo.email })
      }
      dispatch(setPersonalInfoFulfilled())
      dispatch(loadProfileStart())
      if (didChangeEmail) {
        dispatch(currentUserStart())
        dispatch(loadPotentialTeamsStart(true))
      }
    } catch (err) {
      dispatch(setPersonalInfoRejected(err))
    }
  }
}

export const updatePasswordStart = values => dispatch => {
  return dispatch(updatePassword(attemptChangePassword(values)))
}

export const loadProfileAttributesStart = () => dispatch => {
  return dispatch(setProfileAttributes(getUserAttributes()))
}

export const loadUserEmailPreferenceCenterStart = userId => dispatch => {
  return dispatch(setUserEmailPreferenceCenter(getUserEmailPreferenceCenter(userId)))
}

export const updateProfileAttributesStart = values => {
  return async dispatch => {
    await dispatch(updateProfileAttributes(updateUserAttributes(values)))
    dispatch(loadProfileAttributesStart())
  }
}
// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [PENDING(PASSWORD_UPDATE)]: state => {
    return {
      ...state,
      isUpdatingPassword: true,
      successUpdatingPassword: false,
      errorUpdatingPassword: null,
    }
  },
  [REJECTED(PASSWORD_UPDATE)]: (state, action) => {
    const {
      payload: { message },
    } = action
    return {
      ...state,
      isUpdatingPassword: false,
      successUpdatingPassword: false,
      errorUpdatingPassword: message,
    }
  },
  [FULFILLED(PASSWORD_UPDATE)]: (state, action) => {
    return {
      ...state,
      passwordEditMode: false,
      isUpdatingPassword: false,
      successUpdatingPassword: true,
      errorUpdatingPassword: false,
    }
  },
  [DELETE_PAYMENT_OPTION]: (state, action) => {
    const {
      payload: { ccProfileId },
    } = action
    const previousPaymentOptions = state.paymentOptions
    const paymentOptions = previousPaymentOptions.filter(
      option => option.ccProfileId !== ccProfileId,
    )
    return {
      ...state,
      isDeletingPaymentOption: true,
      paymentOptions,
      previousPaymentOptions,
    }
  },
  [DELETE_PAYMENT_OPTION_FAILURE]: (state, action) => {
    const { error } = action
    const paymentOptions = state.previousPaymentOptions
    return {
      ...state,
      error,
      isDeletingPaymentOption: false,
      paymentOptions,
      previousPaymentOptions: undefined,
    }
  },
  [DELETE_PAYMENT_OPTION_SUCCESS]: state => {
    return {
      ...state,
      error: undefined,
      isDeletingPaymentOption: false,
      previousPaymentOptions: undefined,
    }
  },
  [GET_USER_PRIVACY]: state => {
    return {
      ...state,
      userPrivacyLoading: true,
    }
  },
  [GET_USER_PRIVACY_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      updateUserPrivacyError: error.message,
      userPrivacyLoading: false,
    }
  },
  [GET_USER_PRIVACY_SUCCESS]: (state, action) => {
    const {
      payload: { privacy },
    } = action
    return {
      ...state,
      privacy: privacy,
      userPrivacyLoading: false,
    }
  },
  [LOAD_PAYMENT_OPTIONS]: state => {
    return {
      ...state,
      isLoadingPaymentOptions: true,
    }
  },
  [LOAD_PAYMENT_OPTIONS_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      error,
      isLoadingPaymentOptions: false,
    }
  },
  [LOAD_PAYMENT_OPTIONS_SUCCESS]: (state, action) => {
    const {
      payload: { paymentOptions },
    } = action
    return {
      ...state,
      error: undefined,
      isLoadingPaymentOptions: false,
      paymentOptions,
    }
  },
  [PASSWORD_EDIT_MODE_SET]: (state, action) => {
    return {
      ...state,
      errorUpdatingPassword: undefined,
      passwordEditMode: action.payload,
    }
  },
  [PERSONAL_INFO_EDIT_MODE_SET]: (state, action) => {
    return {
      ...state,
      errorUpdatingPersonalInfo: null,
      personalInfoEditMode: action.payload,
    }
  },
  [PENDING(PROFILE_SET)]: state => {
    return {
      ...state,
      isLoadingProfile: true,
    }
  },
  [REJECTED(PROFILE_SET)]: (state, action) => {
    const { payload } = action
    return {
      ...state,
      error: payload,
      isLoadingProfile: false,
    }
  },
  [FULFILLED(PROFILE_SET)]: (state, action) => {
    const { payload } = action
    return {
      ...state,
      error: undefined,
      isLoadingProfile: false,
      profile: payload,
      profileInitialized: true,
    }
  },
  [PENDING(PERSONAL_INFO_SET)]: state => {
    return {
      ...state,
      isUpdatingPersonalInfo: true,
      errorUpdatingPersonalInfo: undefined,
      successUpdatingPersonalInfo: false,
    }
  },
  [REJECTED(PERSONAL_INFO_SET)]: (state, action) => {
    return {
      ...state,
      isUpdatingPersonalInfo: false,
      errorUpdatingPersonalInfo: action.payload,
      successUpdatingPersonalInfo: false,
    }
  },
  [FULFILLED(PERSONAL_INFO_SET)]: state => {
    return {
      ...state,
      personalInfoEditMode: false,
      isUpdatingPersonalInfo: false,
      errorUpdatingPersonalInfo: undefined,
      successUpdatingPersonalInfo: true,
    }
  },
  [UPDATE_PROFILE_PICTURE]: state => {
    return {
      ...state,
      updateProfilePictureError: undefined,
      updateProfilePictureLoading: true,
    }
  },
  [UPDATE_PROFILE_PICTURE_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      updateProfilePictureError: error,
      updateProfilePictureLoading: false,
    }
  },
  [UPDATE_PROFILE_PICTURE_SUCCESS]: (state, action) => {
    const {
      payload: { response },
    } = action
    return {
      ...state,
      profile: { ...state.profile, avatarId: response.avatarId },
      updateProfilePictureLoading: false,
    }
  },
  [UPDATE_USER_PRIVACY]: state => {
    return {
      ...state,
      userPrivacyLoading: true,
    }
  },
  [UPDATE_USER_PRIVACY_FAILURE]: (state, action) => {
    const { error } = action
    return {
      ...state,
      updateUserPrivacyError: error,
      userPrivacyLoading: false,
    }
  },
  [UPDATE_USER_PRIVACY_SUCCESS]: (state, action) => {
    const {
      payload: { userPrivacy },
    } = action
    return {
      ...state,
      privacy: userPrivacy,
      userPrivacyLoading: false,
    }
  },

  [PENDING(PROFILE_ATTRIBUTES_SET)]: state => {
    return {
      ...state,
      isLoadingCuisinePreference: true,
    }
  },
  [REJECTED(PROFILE_ATTRIBUTES_SET)]: (state, action) => {
    const { payload } = action
    return {
      ...state,
      errorSettingCuisinePreferences: payload,
      isLoadingCuisinePreference: false,
    }
  },
  [FULFILLED(PROFILE_ATTRIBUTES_SET)]: (state, action) => {
    return {
      ...state,
      errorSettingCuisinePreferences: undefined,
      additionalAttributes: action.payload,
      isLoadingCuisinePreference: false,
    }
  },

  [PENDING(EMAIL_PREFERENCE_URL_SET)]: state => {
    return {
      ...state,
      isLoadingEmailPreferenceUrl: true,
    }
  },
  [REJECTED(EMAIL_PREFERENCE_URL_SET)]: (state, action) => {
    const { payload } = action
    return {
      ...state,
      errorSettingEmailPreferenceUrl: payload,
      isLoadingEmailPreferenceUrl: false,
    }
  },
  [FULFILLED(EMAIL_PREFERENCE_URL_SET)]: (state, action) => {
    return {
      ...state,
      errorSettingEmailPreferenceUrl: undefined,
      emailPreferenceUrl: action.payload.preferenceCenterUrl,
      isLoadingEmailPreferenceUrl: false,
    }
  },

  [PENDING(PROFILE_ATTRIBUTES_UPDATE)]: state => {
    return {
      ...state,
      isUpdatingCuisinePreference: true,
    }
  },
  [REJECTED(PROFILE_ATTRIBUTES_UPDATE)]: (state, action) => {
    const { payload } = action
    return {
      ...state,
      errorUpdatingCuisinePreferences: payload,
      isUpdatingCuisinePreference: false,
    }
  },

  [FULFILLED(PROFILE_ATTRIBUTES_UPDATE)]: (state, action) => {
    return {
      ...state,
      errorUpdatingCuisinePreferences: undefined,
      additionalAttributes: action.payload,
      isUpdatingCuisinePreference: false,
      isLoadingCuisinePreference: true,
    }
  },
}
// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  profile: {},
  token: false,
  tokenValid: true,
  updatingModal: undefined,
  additionalAttributes: {},
  emailPreferenceUrl: null,
  // Loading states
  profileInitialized: false,
  isLoadingProfile: false,
  isUpdatingPersonalInfo: false,
  isUpdatingPassword: false,
  // Success states
  successUpdatingPassword: false,
  successUpdatingPersonalInfo: false,
  // Error states
  errorUpdatingPassword: null,
  errorUpdatingPersonalInfo: null,
  // Modes
  passwordEditMode: false,
  personalInfoEditMode: false,
}

export default function profile(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

// ------------------------------------
// Sagas
// ------------------------------------
export function* watchLoadPaymentOptions() {
  while (true) {
    yield take(LOAD_PAYMENT_OPTIONS)
    yield call(
      apiSaga,
      loadPaymentOptions,
      [],
      loadPaymentOptionsSuccess,
      loadPaymentOptionsFailure,
    )
  }
}

export function* watchDeletePaymentOption() {
  while (true) {
    const {
      payload: { ccProfileId },
    } = yield take(DELETE_PAYMENT_OPTION)
    yield call(
      apiSaga,
      deletePaymentOption,
      [ccProfileId],
      deletePaymentOptionSuccess,
      deletePaymentOptionFailure,
    )
  }
}

export function* watchGetUserPrivacy() {
  while (true) {
    yield take(GET_USER_PRIVACY)
    const { userId } = yield select(selectProfile)

    yield call(apiSaga, getUserPrivacy, [userId], getUserPrivacySuccess, getUserPrivacyFailure)
  }
}

export function* watchUpdateUserPrivacy() {
  while (true) {
    const {
      payload: { userPrivacy },
    } = yield take(UPDATE_USER_PRIVACY)
    const { userId } = yield select(selectProfile)
    yield call(
      apiSaga,
      updateUserPrivacy,
      [userId, userPrivacy],
      updateUserPrivacySuccess,
      updateUserPrivacyFailure,
    )
  }
}

export function* watchUpdateProfilePicture() {
  while (true) {
    const {
      payload: { image },
    } = yield take(UPDATE_PROFILE_PICTURE)
    const { userId } = yield select(selectProfile)

    let form = new FormData()
    form.append('image', image, image.path)

    yield call(
      formSaga,
      'profilePictureForm',
      updateProfilePicture,
      [userId, form],
      updateProfilePictureSuccess,
      updateProfilePictureFailure,
    )
  }
}
