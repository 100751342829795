import { Spinner } from '@foodsby/nutrient'
import { differenceInCalendarDays } from 'date-fns'
import React from 'react'
import { connect } from 'react-redux'

import { loadDeliveringRestaurants } from '../../redux/modules/comingSoon'
import CollectEmailPanel from '../home/CollectEmailPanel'
import OverSixDays from './OverSixDays'
import UnderSixDays from './UnderSixDays'

export const JoinForm = ({ locationId, toggleJoin }) => (
  <div className="row join-form">
    <div className="col-xs-12" style={{ textAlign: 'left' }}>
      <CollectEmailPanel locationId={locationId} toggleJoin={toggleJoin} />
    </div>
  </div>
)

class ComingSoon extends React.Component {
  componentDidMount() {
    this.props.loadDeliveringRestaurants(this.props.locationId)
  }

  render() {
    const {
      activationDate,
      isAuthenticated,
      loadDeliveriesForLocation,
      loadingDeliveringRestaurants,
      locationId,
      onChangeDay,
    } = this.props
    const DAYS_UNTIL_LAUNCH = differenceInCalendarDays(activationDate, new Date())
    return (
      <div>
        {DAYS_UNTIL_LAUNCH < 6 ? (
          <UnderSixDays
            activationDate={activationDate}
            isAuthenticated={isAuthenticated}
            JoinForm={JoinForm}
            loadingDeliveringRestaurants={loadingDeliveringRestaurants}
            locationId={locationId}
            onChangeDay={onChangeDay}
            restaurantLogos={loadDeliveriesForLocation}
          />
        ) : DAYS_UNTIL_LAUNCH >= 6 ? (
          <OverSixDays
            activationDate={activationDate}
            daysUntilLaunch={DAYS_UNTIL_LAUNCH}
            isAuthenticated={isAuthenticated}
            JoinForm={JoinForm}
            loadingDeliveringRestaurants={loadingDeliveringRestaurants}
            locationId={locationId}
            restaurantLogos={loadDeliveriesForLocation}
          />
        ) : (
          <div className="row center-xs">
            <Spinner className="spinner-lg primary" />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { loadDeliveriesForLocation, loadingDeliveringRestaurants } = state.comingSoon
  const { location } = state.location
  return {
    loadDeliveriesForLocation,
    loadingDeliveringRestaurants,
    location,
  }
}

const mapDispatchToProps = {
  loadDeliveringRestaurants,
}

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoon)
