import { format } from 'date-fns'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { destroy } from 'redux-form'
import { Box, Card, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import CheckoutForm from '../components/checkout/CheckoutForm'
import { ValidationModal } from '../components/place-order'
import { loadCheckoutCartStart } from '../redux/modules/cart'
import { resetCheckoutError, loadAvailableProgramStart } from '../redux/modules/checkout'
import { loadPaymentMethodsStart } from '../redux/modules/paymentMethods'
import { loadAvailableCreditsStart } from '../redux/modules/creditHistory'
import { loadDeliveryDropoffStart } from '../redux/modules/delivery'
import { loadDeliveryDropoffRewardsStart } from '../redux/modules/deliveryDropoffRewards'
import { selectCurrentLocationWithAddress } from '../redux/modules/selectors'
import CheckoutSidebar from '../components/checkout/CheckoutSidebar'
import LayoutInnerPage, { HeaderInnerPage } from '../layouts/LayoutInnerPage'
import { formatUrl } from '../util/formatUtils'
import { formatRewardsPercentage } from '../util/formatUtils'
import { placeOrderRoute } from '../routes/routes'
import { loadCateringDeliveryDateAndTimeStart } from '../redux/modules/delivery'
import { loadCateringStoreStart } from '../redux/modules/catering'
import PageCateringCheckout from './PageCateringCheckout'
import PageReserveCheckout from './PageReserveCheckout'

const useStyles = makeStyles({
  formTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '38px',
  },
})

const PageCheckout = ({
  match: {
    params: { dropoffId = 0, locationId = 0 },
  },
  checkoutCart,
  resetCheckoutError,
  deliveryError,
  destroy,
  loadAvailableCreditsStart,
  loadAvailableProgramStart,
  loadCateringDeliveryDateAndTimeStart,
  loadCateringStoreStart,
  loadCheckoutCartStart,
  loadPaymentMethodsStart,
  loadDeliveryDropoffStart,
  deliveryDropoff,
  isDeliveryDropoffLoading,
  loadDeliveryDropoffRewardsStart,
  nextRewardsPercentage,
  isLoadingDeliveryDropoffRewards,
  selectedDate,
}) => {
  const classes = useStyles()
  // runs once on mount, and returned function runs on unmount
  useEffect(() => {
    loadPaymentMethodsStart()
    loadAvailableCreditsStart()
    return () => {
      resetCheckoutError()
      destroy('checkout')
    }
  }, [resetCheckoutError, destroy, loadAvailableCreditsStart, loadPaymentMethodsStart])

  // when dropoffId updates
  useEffect(() => {
    const numId = Number(dropoffId)
    if (numId) {
      loadDeliveryDropoffStart(numId)
    }
  }, [dropoffId, loadDeliveryDropoffStart])

  const isCatering = deliveryDropoff?.type === 'Catering'
  const isReserve = deliveryDropoff?.type === 'Reserve'

  useEffect(() => {
    const numId = Number(dropoffId)
    if (numId) {
      loadDeliveryDropoffRewardsStart(numId)
    }
  }, [dropoffId, loadDeliveryDropoffRewardsStart])

  // When subscription value changes
  useEffect(() => {
    const numDropoffId = Number(dropoffId)
    const numLocationId = Number(locationId)
    const storeId = deliveryDropoff?.storeId

    if (numDropoffId && !isCatering) {
      loadCheckoutCartStart(numDropoffId)
    } else if (numDropoffId && isCatering) {
      loadCheckoutCartStart(numDropoffId)
      loadCateringDeliveryDateAndTimeStart(storeId, numLocationId)
      loadCateringStoreStart(storeId, numLocationId)
    }
  }, [dropoffId, loadCheckoutCartStart, deliveryDropoff])

  // when orderDate changes
  useEffect(() => {
    if (checkoutCart.orderDate) {
      loadAvailableProgramStart(format(checkoutCart.orderDate, 'YYYY-MM-DD'))
    }
  }, [checkoutCart.orderDate, loadAvailableProgramStart])

  locationId = Number(locationId)
  const storeId = deliveryDropoff?.storeId

  const rewardsPercentage = formatRewardsPercentage(nextRewardsPercentage)

  if (deliveryError) {
    return (
      <>
        <SkeletonLoading />
        <ValidationModal locationId={locationId} serverMessage={deliveryError.data} />
      </>
    )
  }

  if (isDeliveryDropoffLoading || deliveryDropoff.type === undefined || storeId === undefined) {
    return <SkeletonLoading />
  }

  if (isCatering) {
    return <PageCateringCheckout />
  }

  if (isReserve) {
    return (
      <PageReserveCheckout
        rewardsPercentage={rewardsPercentage}
        isLoadingDeliveryDropoffRewards={isLoadingDeliveryDropoffRewards}
      />
    )
  }

  return (
    <LayoutInnerPage
      HeaderComponent={
        <HeaderInnerPage
          showBackButton
          backButtonRoute={formatUrl(placeOrderRoute.path, { locationId, dropoffId })}
        >
          Back
        </HeaderInnerPage>
      }
      MainComponent={
        <Card>
          <Box marginBottom={4}>
            <Typography className={classes.formTitle}>Review & Checkout</Typography>
          </Box>
          <CheckoutForm dropoffId={dropoffId} />
        </Card>
      }
      SidebarComponent={
        <CheckoutSidebar
          deliveryType={deliveryDropoff?.type}
          showRestaurantLogo={isCatering}
          enableQuantity={isCatering}
          rewardsPercentage={rewardsPercentage}
          isLoadingDeliveryDropoffRewards={isLoadingDeliveryDropoffRewards}
        />
      }
    />
  )
}

const SkeletonLoading = () => (
  <LayoutInnerPage
    HeaderComponent={
      <HeaderInnerPage>
        <Skeleton height={80} width={200} />
      </HeaderInnerPage>
    }
    MainComponent={
      <Card>
        <Box marginTop={'-10em'} marginBottom={'-6em'}>
          <Skeleton height={800} />
        </Box>
      </Card>
    }
    SidebarComponent={
      <>
        <Skeleton height={150} variant="rect" />
        <Box padding={3}>
          <Skeleton height={30} width="80%" />
          <Skeleton height={30} width="80%" />
          <Box marginY={2}>
            <Skeleton height={40} width="90%" />
          </Box>
          <Skeleton height={50} />
        </Box>
      </>
    }
  />
)

const mapStateToProps = state => {
  const deliveryError = state.delivery.errorLoading
  const { deliveryDropoff, isDeliveryDropoffLoading, selectedDate } = state.delivery
  const { nextRewardsPercentage, isLoadingDeliveryDropoffRewards } = state.deliveryDropoffRewards
  const { availableCredits } = state.creditHistory
  const { checkoutCart, couponUsage } = state.cart
  const location = selectCurrentLocationWithAddress(state)

  return {
    availableCredits,
    checkoutCart,
    couponUsage,
    deliveryError,
    location,
    deliveryDropoff,
    isDeliveryDropoffLoading,
    selectedDate,
    nextRewardsPercentage,
    isLoadingDeliveryDropoffRewards,
  }
}

const mapDispatchToProps = {
  resetCheckoutError,
  destroy,
  loadAvailableCreditsStart,
  loadAvailableProgramStart,
  loadCheckoutCartStart,
  loadDeliveryDropoffRewardsStart,
  loadCateringStoreStart,
  loadCateringDeliveryDateAndTimeStart,
  loadPaymentMethodsStart,
  loadDeliveryDropoffStart,
}
export default connect(mapStateToProps, mapDispatchToProps)(PageCheckout)
