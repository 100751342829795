import { Card } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { isEmpty } from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getUserPrivacyStart,
  updateUserPrivacyStart,
  loadProfileAttributesStart,
  loadUserEmailPreferenceCenterStart,
  updateProfileAttributesStart,
} from '../../redux/modules/profile'
import { PanelPrivacyPreferences } from './PanelPrivacyPreferences'
import { PanelEmailPreferences } from './PanelEmailPreference'

class ProfileSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = { fetchedData: false }
    this.props.loadProfileAttributesStart()
  }

  componentDidUpdate() {
    const {
      getUserPrivacyStart,
      isLoadingEmailPreferenceUrl,
      privacy,
      profile,
      updateUserPrivacyError,
      userPrivacyLoading,
      loadUserEmailPreferenceCenterStart,
      emailPreferenceUrl,
    } = this.props
    if (!privacy && !userPrivacyLoading && profile && !updateUserPrivacyError) {
      getUserPrivacyStart()
    }
    if (!emailPreferenceUrl && profile) {
      loadUserEmailPreferenceCenterStart(profile.userId)
    }
    if (this.state.fetchedData === false && !userPrivacyLoading && !isLoadingEmailPreferenceUrl) {
      this.setState({ fetchedData: true })
    }
  }

  render() {
    const {
      isLoadingEmailPreferenceUrl,
      privacy,
      updateUserPrivacyError,
      updateUserPrivacyStart,
      userPrivacyLoading,
      emailPreferenceUrl,
    } = this.props
    return (
      <>
        <PanelEmailPreferences
          isLoadingEmailPreferenceUrl={isLoadingEmailPreferenceUrl}
          emailPreferenceUrl={emailPreferenceUrl}
        />
        <Card>
          {(userPrivacyLoading || !this.state.fetchedData || isEmpty(privacy)) &&
          !this.state.fetchedData ? (
            <SkeletonPanelPrivacyPreferences />
          ) : (
            <PanelPrivacyPreferences
              privacy={privacy}
              updateUserPrivacyError={updateUserPrivacyError}
              updateUserPrivacyStart={updateUserPrivacyStart}
              userPrivacyLoading={userPrivacyLoading}
            />
          )}
        </Card>
      </>
    )
  }
}

const mapStateToProps = state => {
  const {
    privacy,
    profile,
    updateUserPrivacyError,
    userPrivacyLoading,
    additionalAttributes,
    emailPreferenceUrl,
    isUpdatingCuisinePreference,
    isLoadingEmailPreferenceUrl,
    errorSettingCuisinePreferences,
    errorUpdatingCuisinePreferences,
  } = state.profile

  return {
    isUpdatingCuisinePreference,
    isLoadingEmailPreferenceUrl,
    privacy,
    profile,
    updateUserPrivacyError,
    userPrivacyLoading,
    additionalAttributes,
    emailPreferenceUrl,
    errorSettingCuisinePreferences,
    errorUpdatingCuisinePreferences,
  }
}

const mapDispatchToProps = {
  getUserPrivacyStart,
  updateUserPrivacyStart,
  loadProfileAttributesStart,
  loadUserEmailPreferenceCenterStart,
  updateProfileAttributesStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSidebar)

const SkeletonPanelPrivacyPreferences = () => (
  <>
    <Skeleton height={40} width="100%" />
    <Skeleton height={56} width="100%" />
  </>
)
