import React from 'react'
import { Button, Box } from '@material-ui/core'
import { push } from 'connected-react-router'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { selectLocationPreferred } from '../../redux/selectors/locationSelectors'
import { selectCurrentUser, selectIsAuthenticated } from '../../redux/modules/selectors'
import { userHidRequestPreferred } from './requestPreferred'

import HomeAd from './HomeAd'

const HomePreferredLocation = () => {
  const dispatch = useDispatch()
  const isPreferredLocation = useSelector(selectLocationPreferred)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const currentUser = useSelector(selectCurrentUser)
  const showRequestPreferred = !isPreferredLocation && !userHidRequestPreferred(currentUser?.userId)
  if (!showRequestPreferred || !isAuthenticated) {
    return null
  }

  const handleClick = () => {
    dispatch(push({ search: queryString.stringify({ requestPreferred: 'true' }) }))
  }

  return (
    <Box>
      <HomeAd
        description="Save 50% or more on service fees and become a preferred location!"
        actions={
          <Button variant="contained" color="primary" onClick={() => handleClick()}>
            Learn more
          </Button>
        }
      />
    </Box>
  )
}

export default HomePreferredLocation
