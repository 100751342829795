import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectLocationPreferred } from '../../redux/selectors/locationSelectors'
import { selectOfficeAccounts } from '../../redux/selectors/accountSelectors'
import DialogUpgradeOffice from './DialogUpgradeOffice'
import HomeAd from './HomeAd'

const HomeUnlockPreferredPricing = () => {
  const [showDialog, setShowDialog] = useState(false)
  const isPreferredLocation = useSelector(selectLocationPreferred)
  const offices = useSelector(selectOfficeAccounts)
  const standardOffice = offices.find(office => office.preferredType === 'STANDARD')
  const hasStandardOffice = standardOffice !== undefined

  if (!isPreferredLocation || !hasStandardOffice) {
    return null
  }

  const handleOpen = () => {
    setShowDialog(true)
  }

  const handleClose = (_, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    setShowDialog(false)
  }
  return (
    <>
      <Box>
        <HomeAd
          description="Access special offers and features for everyone at your office!"
          actions={
            <Button
              onClick={handleOpen}
              color="primary"
              variant="contained"
              style={{ width: '12rem' }}
            >
              Complete Setup
            </Button>
          }
        />
      </Box>
      <DialogUpgradeOffice
        showDialog={showDialog}
        closeDialog={handleClose}
        officeId={standardOffice?.id}
      />
    </>
  )
}

export default HomeUnlockPreferredPricing
