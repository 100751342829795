import React from 'react'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import ButtonDialogClose from '../teams/common/ButtonDialogClose'
import { SelectEmployeeCount } from '../teams/common/EmployeeCount'

const schema = object().shape({
  companyName: string().required("Please enter your company's name."),
  estimatedEmployeeCount: string().required('Please select # of workers.'),
})

const useStyles = makeStyles(theme => ({
  dialogAction: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
}))

const DialogUpgradeOfficeYourOfficeInfo = ({ closeDialog, handleNext, setFormData }) => {
  const classes = useStyles()

  const handleSubmit = values => {
    setFormData(values)
    handleNext()
  }

  return (
    <>
      <DialogTitle disableTypography>
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            <strong>Step 1:</strong> Tell us about your office
          </Typography>
        </Box>
        <ButtonDialogClose onClick={closeDialog} />
      </DialogTitle>
      <Formik
        initialValues={{
          companyName: '',
          estimatedEmployeeCount: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: '500' }}>
                    What is the name of your company?
                  </Typography>
                  <TextField
                    error={touched.companyName && Boolean(errors.companyName)}
                    helperText={touched.companyName && errors.companyName}
                    fullWidth
                    id="companyName"
                    name="companyName"
                    placeholder="Company name"
                    margin="dense"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.companyName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: '500' }}>
                    How many people work at your office, on any given day?
                  </Typography>
                  <FormControl fullWidth>
                    <SelectEmployeeCount
                      id="estimatedEmployeeCount"
                      name="estimatedEmployeeCount"
                      displayEmpty
                      size="small"
                      margin="dense"
                      variant="outlined"
                      value={values.estimatedEmployeeCount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                  {touched.estimatedEmployeeCount && Boolean(errors.estimatedEmployeeCount) && (
                    <Box style={{ marginLeft: '1rem' }}>
                      <FormHelperText error className={classes.helperText}>
                        {errors.estimatedEmployeeCount}
                      </FormHelperText>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
              <Button variant="contained" color="primary" type="submit">
                Continue
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default DialogUpgradeOfficeYourOfficeInfo
