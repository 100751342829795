import React from 'react'
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ROWS_PER_PAGE } from '../admin/adminUtils'
import HeaderTableMembers from './HeaderTableMembers'

const useStyles = makeStyles({
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
})

const TableMembers = ({
  checkbox,
  columns,
  teamMembers,
  filteredMembers,
  selectedMembers,
  rows,
  rowCells,
  page,
  sort,
  onSelectOnePage,
  onSelectAll,
  setPage,
  setSort,
  loading,
  Toolbox,
}) => {
  const classes = useStyles()

  const handleChangePage = (e, page) => {
    setPage(page)
  }

  const handleSort = (e, property) => {
    const isAsc = sort.field === property && sort.direction === 'asc'
    setSort({
      field: property,
      direction: isAsc ? 'desc' : 'asc',
    })
  }

  const totalAvailableMembers = filteredMembers?.length || teamMembers.length
  return (
    <>
      <TableContainer component={Paper}>
        {loading && <CircularProgress className={classes.circularProgress} />}
        <Table>
          <HeaderTableMembers
            checkbox={checkbox}
            teamMembers={teamMembers}
            columns={columns}
            rows={rows}
            selectedMembers={selectedMembers}
            sort={sort}
            onSelectOnePage={onSelectOnePage}
            onSelectAll={onSelectAll}
            onSort={handleSort}
            Toolbox={Toolbox}
          />
          <TableBody>
            {rowCells.map(rowCell => (
              <TableRow key={rowCell.memberId}>
                {rowCell.cells.map(({ labelId, Component }, index) => (
                  <TableCell key={index} id={labelId}>
                    {Component}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalAvailableMembers > ROWS_PER_PAGE && (
        <TablePagination
          component="div"
          count={totalAvailableMembers}
          page={page}
          rowsPerPage={ROWS_PER_PAGE}
          rowsPerPageOptions={[]}
          onChangePage={handleChangePage}
        />
      )}
    </>
  )
}

export default TableMembers
