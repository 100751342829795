import { createSelector } from 'reselect'
import { ROWS_PER_PAGE } from '../../components/teams/admin/adminUtils'

export const selectMissingMembers = createSelector(
  [state => state.teamAddPerkMembers.missingMembers],
  missingMembers => missingMembers,
)

export const selectMissingMembersRows = createSelector(
  [
    selectMissingMembers,
    state => state.teamAddPerkMembers.sort,
    state => state.teamAddPerkMembers.page,
  ],
  (missingMembers, sort, page) =>
    stableSort(missingMembers, getComparator(sort.field, sort.direction)).slice(
      page * ROWS_PER_PAGE,
      page * ROWS_PER_PAGE + ROWS_PER_PAGE,
    ),
)

// TODO This is copy-pasta from `teamMembersSelectors` so they can be moved to a shared file
// ------------------------------------
// Private methods
// ------------------------------------
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const descendingComparator = (a, b, field) => {
  // TODO Handle date comparison here
  if (b[field] < a[field]) {
    return -1
  }
  if (b[field] > a[field]) {
    return 1
  }

  return 0
}

const getComparator = (field, direction) => {
  return direction === 'desc'
    ? (a, b) => descendingComparator(a, b, field)
    : (a, b) => -descendingComparator(a, b, field)
}
