import { Box, Card, Typography } from '@material-ui/core'
import React from 'react'
export const RestaurantShowcase = restaurantLogos => {
  return (
    <Card>
      <Box paddingY={3}>
        {restaurantLogos.restaurantLogos.length > 0 ? (
          <div className="restaurantshowcase">
            <Typography component="h2" variant="h5">
              Restaurants delivering in your area
            </Typography>
            <div className="row center-xs middle-xs icon-rows">
              {restaurantLogos.restaurantLogos.map(restaurant => {
                return (
                  <div
                    className="col-xs-12 col-md-4 icon-logo-container"
                    key={restaurant.restaurantName}
                  >
                    <img alt={restaurant.restaurantName} src={restaurant.logoUrl} />
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <div className="restaurantshowcase">
            <Typography id="deliveries-coming-soon" style={{ marginBottom: '0' }}>
              Delivering restaurants coming soon
            </Typography>
          </div>
        )}
      </Box>
    </Card>
  )
}

export default RestaurantShowcase
