import React from 'react'

const styles = {
  appLinks: {
    padding: '0px 12px',
  },
}

export const PreferMobile = () => {
  return (
    <div className="prefer-mobile">
      <h5 style={{ marginBottom: '16px' }}>Download the Foodsby App</h5>
      <p style={{ marginBottom: '16px' }}>
        See restaurant options, receive daily reminders, and place orders from anywhere.
      </p>
      <p>
        <a
          href="https://itunes.apple.com/us/app/foodsby/id875062433?mt=8"
          rel="noopener noreferrer"
          style={styles.appLinks}
          target="_blank"
        >
          <img
            alt="icon-app-store"
            className="app-store-button"
            height="42"
            src={'https://images.foodsby.com/www/theme/icon-app-store.png'}
            width="140"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.foodsby.consumerApp&amp;hl=en"
          rel="noopener noreferrer"
          style={styles.appLinks}
          target="_blank"
        >
          <img
            alt="icon-google-play"
            className="app-store-button"
            height="42"
            src={'https://images.foodsby.com/www/theme/icon-google-play.png'}
            width="141"
          />
        </a>
      </p>
    </div>
  )
}

export default PreferMobile
