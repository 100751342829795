import { Box, Button, Dialog, Step, StepLabel, Stepper } from '@material-ui/core'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DialogUpgradeOfficeYourOfficeInfo from './DialogUpgradeOfficeYourOfficeInfo'
import DialogUpgradeOfficeYourInfo from './DialogUpgradeOfficeYourInfo'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      padding: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
      },
    },
  },
}))

const useStepperStyles = makeStyles(() => ({
  root: {
    justifyContent: 'center',
  },
}))

const useStepIconStyles = makeStyles({
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'red',
  },
  inactiveCircle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#eaeaf0',
  },
})

const DialogUpgradeOffice = ({ showDialog, closeDialog, officeId }) => {
  const classes = useStyles()
  const stepperClasses = useStepperStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [formData, setFormData] = useState({})
  if (!showDialog) {
    return null
  }
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const StepIcon = ({ active }) => {
    const stepIconClasses = useStepIconStyles()
    return (
      <>
        {active ? (
          <div className={stepIconClasses.circle} />
        ) : (
          <div className={stepIconClasses.inactiveCircle} />
        )}
      </>
    )
  }

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <DialogUpgradeOfficeYourOfficeInfo
            closeDialog={closeDialog}
            handleNext={handleNext}
            setFormData={setFormData}
          />
        )
      case 1:
        return (
          <DialogUpgradeOfficeYourInfo
            closeDialog={closeDialog}
            formData={formData}
            officeId={officeId}
          />
        )
      default:
        return (
          <DialogUpgradeOfficeYourOfficeInfo
            closeDialog={closeDialog}
            handleNext={handleNext}
            setFormData={setFormData}
          />
        )
    }
  }
  return (
    <Dialog open maxWidth="sm" className={classes.root} onClose={closeDialog}>
      {activeStep !== 0 ? (
        <Box width={240}>
          <Button variant="text" color="primary" onClick={handleBack}>
            Back
          </Button>
        </Box>
      ) : null}
      <Stepper classes={{ root: stepperClasses.root }} activeStep={activeStep} connector={null}>
        <Step>
          <StepLabel StepIconComponent={StepIcon} />
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon} />
        </Step>
      </Stepper>
      {getStepContent(activeStep)}
    </Dialog>
  )
}

export default DialogUpgradeOffice
