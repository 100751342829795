import React from 'react'

const HowItWorks = () => (
  <div className="howitworks">
    <div className="row center-xs bucket-rows">
      <div className="col-xs-12 col-sm-4">
        <div className="how-to-bucket">
          <div className="how-to-card">
            <div>
              <img
                alt="Order Your Meal"
                src={'https://images.foodsby.com/www/theme/cart_icon.svg'}
              />
            </div>
            <div className="how-it-works-point col-xs-12">
              <p>
                <b>Order Online</b>
              </p>
              <p className="sub-point">
                Normal menu prices, $1.99 <br />
                delivery
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-4">
        <div className="how-to-bucket">
          <div className="how-to-card">
            <div>
              <img
                alt="Get It Delivered"
                src={'https://images.foodsby.com/www/theme/car_icon.svg'}
              />
            </div>
            <div className="how-it-works-point col-xs-12">
              <p>
                <b>Get It Delivered</b>
              </p>
              <p className="sub-point">
                No tipping, no <br />
                minimum, and on-time
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-4">
        <div className="how-to-bucket">
          <div className="how-to-card">
            <div>
              <img
                alt="Pickup Your Meal At Your Location"
                src={'https://images.foodsby.com/www/theme/bag_icon.svg'}
              />
            </div>
            <div className="how-it-works-point col-xs-12">
              <p>
                <b>Enjoy Your Meal</b>
              </p>
              <p className="sub-point">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                We'll notify you when <br /> your meal arrives
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default HowItWorks
