import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Form, Formik } from 'formik'
import { string, object } from 'yup'
import { push } from 'connected-react-router'

import { useEffect } from 'react'
import { isNil } from 'lodash'
import { selectQuery } from '../../redux/selectors/routerSelectors'
import { selectCurrentLocationWithAddress, selectProfile } from '../../redux/modules/selectors'
import ButtonSubmit from '../common/ButtonSubmit'
import ButtonDialogClose from '../teams/common/ButtonDialogClose'
import { useHubspotSubmitLead } from '../../hooks/useHubspotSubmitLead'
import { enqueueSnackbar } from '../../redux/modules/snackbar'
import { setHideRequestPreferred } from './requestPreferred'

const HUBSPOT_URL = process.env.REACT_APP_HUBSPOT_REQUEST_PREFERRED_FORM_WEBHOOK || ''

const schema = object().shape({
  firstName: string().required('Please enter your first name.'),
  lastName: string().required('Please enter your last name.'),
  email: string()
    .email('Please enter a valid email address.')
    .required('Please enter an email address.'),
  propertyName: string().required('Please enter a property name.'),
  buildingType: string().required('Please select building type.'),
  size: string().required('Please select # of people.'),
})

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialog-paper': {
      padding: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(3),
      },
    },
    '& .MuiDialogTitle-root': {
      textAlign: 'center',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
    },
    '& .MuiDialogContent-root': {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
    },
  },
  formTitle: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    textAlign: 'left',
    marginBottom: theme.spacing(2),
    letterSpacing: '0.1em',
  },
  dialogAction: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
}))

const DialogRequestPreferred = ({ profile, location, push, enqueueSnackbar }) => {
  const classes = useStyles()
  const { submitForm, loading, success } = useHubspotSubmitLead(HUBSPOT_URL)

  const handleClose = () => {
    push({ search: undefined })
  }

  const handleSubmit = values => {
    submitForm(values)
    setHideRequestPreferred(profile.userId)
  }

  useEffect(() => {
    if (success) {
      enqueueSnackbar({
        message: 'Your request was submitted. A member from our team will reach out shortly.',
      })
      push({ search: undefined })
    }
  })

  return (
    <Dialog open maxWidth="sm" fullWidth className={classes.root}>
      <DialogTitle disableTypography>
        <Typography variant="h5" gutterBottom>
          Request Foodsby
        </Typography>
        <Typography variant="body1">
          Submit your property information and a member from our team will reach out shortly.
        </Typography>
        <ButtonDialogClose onClick={handleClose} />
      </DialogTitle>
      <Formik
        initialValues={{
          firstName: profile?.firstName ?? '',
          lastName: profile?.lastName ?? '',
          email: profile?.username ?? '',
          propertyName: '',
          size: '',
          buildingType: '',
          locationId: location.deliveryLocationId,
          street: location.address.street,
          city: location.address.city,
          state: location.address.state,
          zip: location.address.zip,
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <DialogContent>
              <Typography component="h2" className={classes.formTitle}>
                Property information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label="First name"
                    margin="dense"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    fullWidth
                    id="lastName"
                    name="lastName"
                    label="Last name"
                    margin="dense"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    fullWidth
                    id="email"
                    label="Work email"
                    margin="dense"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={touched.propertyName && Boolean(errors.propertyName)}
                    helperText={touched.propertyName && errors.propertyName}
                    fullWidth
                    id="propertyName"
                    label="Property name"
                    margin="dense"
                    name="propertyName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.propertyName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={touched.buildingType && Boolean(errors.buildingType)}
                    helperText={touched.buildingType && errors.buildingType}
                    select
                    fullWidth
                    id="buildingType"
                    name="buildingType"
                    margin="dense"
                    label="Building Type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.buildingType}
                    variant="outlined"
                  >
                    <MenuItem value="OFFICE">Office</MenuItem>
                    <MenuItem value="HOUSE">House</MenuItem>
                    <MenuItem value="APT">Apartment</MenuItem>
                    <MenuItem value="OTHER">Other</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={touched.size && Boolean(errors.size)}
                    helperText={touched.size && errors.size}
                    select
                    fullWidth
                    id="size"
                    name="size"
                    margin="dense"
                    label="Size"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.size}
                    variant="outlined"
                  >
                    <MenuItem value="LESS_THAN_50">{'<50 people'}</MenuItem>
                    <MenuItem value="BETWEEN_50_AND_250">50-250 people</MenuItem>
                    <MenuItem value="OVER_250">250+ people</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
              <ButtonSubmit variant="contained" color="primary" submitting={loading}>
                Submit
              </ButtonSubmit>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

const DialogRequestPreferredWrapper = ({ submitPreferred, location, ...rest }) => {
  const open = Boolean(submitPreferred) && !isNil(location)
  return open ? <DialogRequestPreferred location={location} {...rest} /> : null
}

const mapStateToProps = state => {
  const profile = selectProfile(state)
  const { submitPreferred } = selectQuery(state)
  const location = selectCurrentLocationWithAddress(state)

  return {
    profile,
    submitPreferred,
    location,
  }
}

const mapDispatchToProps = {
  push,
  enqueueSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogRequestPreferredWrapper)
