import { Typography, Link, Card } from '@material-ui/core'
import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { Panel } from '../common/Layout'
import PanelHeader from '../common/PanelHeader'

const useStyles = makeStyles(theme => ({
  accordion: {
    boxShadow: 'none',
    border: 'none',
    '&::before': {
      backgroundColor: 'white',
      height: '0px',
    },
  },
}))

export const PanelEmailPreferences = ({ isLoadingEmailPreferenceUrl, emailPreferenceUrl }) => {
  const classes = useStyles()
  return (
    <Card elevation={0}>
      {isLoadingEmailPreferenceUrl ? (
        <SkeletonPanelEmailPreference />
      ) : (
        <Panel>
          <PanelHeader title="Notification Preferences" />
          <Accordion classes={{ root: classes.accordion }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: '#d43620' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: 0 }}
            >
              <Typography style={{ fontSize: '19px', fontWeight: 700 }}>Email</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Update email preferences{' '}
                <Link href={emailPreferenceUrl} target="_blank">
                  here.
                </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Panel>
      )}
    </Card>
  )
}
export default PanelEmailPreferences

const SkeletonPanelEmailPreference = () => (
  <>
    <Skeleton height={40} width="100%" />
    <Skeleton height={56} width="100%" />
    <Skeleton height={56} width="100%" />
  </>
)
